// AuthContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';

interface AuthContextType {
    isAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const authUser: string | null = localStorage.getItem('authUser');
        const token = authUser ? JSON.parse(authUser).token : null;
        if (token) {
            const payload = token.split('.')[1];

            if (payload) {
                // const decoded = JSON.parse(atob(payload));
                // const exp = decoded.exp * 1000;

                try {
                    const decoded = JSON.parse(atob(payload));
                    const exp = decoded.exp * 1000;

                    if (exp > Date.now()) {
                        setIsAuthenticated(true);
                    } else {
                        localStorage.removeItem('authUser');
                    }
                } catch (error) {
                    console.error("Token decoding failed:", error);
                    localStorage.removeItem('authUser'); // Cleanup in case of failure
                }

            }
        }
    }, []);

    const login = (token: string) => {
        localStorage.setItem('authUser', JSON.stringify({ token }));
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem('authUser');
        setIsAuthenticated(false);
    };

    // console.log("isAuthenticated Status:", isAuthenticated);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
