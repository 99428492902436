import React, { useEffect, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import WelcomeWidget from './WelcomeWidget';
import Widgets from './Widgets';
import BirthdayWFHDashboard from './BirthdayWFHDashboard';
import TaskDashboard from './TaskDashboard';
import { useNavigate } from 'react-router-dom';

import {
    getUserProfile as onGetUserProfile,
    getUsersBirthdays as onGetUsersBirthdays,
    getUsersAnivarsaries as onGetUsersAnivarsaries,
    getUsersCelebrations as onGetUsersCelebrations,
    getUserCelebrationMetrix as onGetUserCelebrationMetrix,
    checkOpenedAppraisalCategories as onCheckOpenedAppraisalCategories,
} from 'slices/thunk';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const Ecommerce = () => {

    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.Users,
        (hrState: any) => hrState.HRManagment,
        (user, hrState) => ({
            profileList: user.userProfile,
            userBirthdays: user.userBirthdays,
            userAnivarsaries: user.userAnivarsaries,
            userCelebrations: user.userCelebrations,
            userCelebrationMetrix: user.userCelebrationMetrix,
            checkAppraisal: hrState.checkAppraisalCategories,
        })
    );
    
    const { profileList, checkAppraisal} = useSelector(selectDataList);
    const [user, setProfile] = useState<any>([]);
    // Get Data
    useEffect(() => {
        dispatch(onGetUsersBirthdays());
        dispatch(onGetUsersCelebrations());  
        dispatch(onGetUsersAnivarsaries());
        dispatch(onGetUserCelebrationMetrix());
        dispatch(onCheckOpenedAppraisalCategories());  
    }, [dispatch]);
    

    return (
        <React.Fragment>
            <BreadCrumb title='Staff Dashboard' pageTitle='Dashboards' />
            <div className="grid grid-cols-12 gap-x-5">
                <WelcomeWidget />
                <BirthdayWFHDashboard />
                {/* <OrderStatistics /> */}
                <Widgets />
                <TaskDashboard />
                {/* <UpcomingScheduled /> */}
                {/* <TrafficResources />
                <ProductsOrders />
                <CustomerService />
                <SalesMonth />
                <TopSellingProducts />
                <Audience /> */}
            </div>
        </React.Fragment>
    );
};

export default Ecommerce;
