import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Trophy,
  UserRound,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/dashboard",
    icon: <MonitorDot />,
    subItems: [
      {
        id: "ecommercedashboard",
        label: "Staff",
        link: "/dashboard",
        parentId: "dashboard",
      },

      {
        id: "analyticsdashboard",
        label: "Admin",
        link: "/dashboards-analytics",
        parentId: "dashboard",
      },

      {
        id: "hrdashboard",
        label: "HR",
        link: "/dashboards-hr",
        parentId: "dashboard",
      },
    ],
  },


  // Staff Management

  {
    label: "HR Click",
    isTitle: true,
  },

  {
    id: "usergridview",
    label: "Employees",
    link: "/apps-users-grid",
    icon: <UserRound />,
    parentId: "usergridview",
  },

  {
    id: "staffAppraisal",
    label: "Appraisals",
    link: "/appaisals",
    icon: <UserRound />,
    parentId: "staffAppraisal",
  },

  // Admin Settings

  {
    label: "Admin Management",
    isTitle: true,
  },

  {
    id: "adminAppraisal",
    label: "Staff Appraisal",
    icon: <UserRound />,
    parentId: "2",
    subItems: [
      {
        id: "appraisalList",
        label: "Appraisal Admin",
        link: "/appaisal-admin",
        parentId: "adminAppraisal",
      },

      {
        id: "appraisalCategories",
        label: "Appraisal Categories",
        link: "/appraisal-categories",
        parentId: "adminAppraisal",
      },

      {
        id: "appraisalKpis",
        label: "Appraisal KPIs",
        link: "/appraisal-kpis",
        parentId: "adminAppraisal",
      },
    ],
  },

  {
    id: "users",
    label: "Users Management",
    icon: <UserRound />,
    parentId: "2",
    subItems: [
      {
        id: "addUsers",
        label: "Create User",
        link: "/apps-create-user",
        parentId: "users",
      },

      {
        id: "userlistview",
        label: "User List",
        link: "/apps-users-list",
        parentId: "users",
      },
    ],
  },

  {
    id: "department",
    label: "Department",
    icon: <CircuitBoard />,
    link: "/apps-hr-department",
    parentId: 2,
  },

  {
    id: "roles",
    label: "Roles",
    icon: <MessageSquare />,
    link: "/apps-admin-roles",
    parentId: 2,
  },

  {
    id: "positions",
    label: "Positions",
    icon: <MessageSquare />,
    link: "/apps-admin-positions",
    parentId: 2,
  },

  {
    id: "user-categories",
    label: "User categories",
    icon: <ScrollText />,
    link: "/apps-admin-userCategories",
    parentId: 2,
  },
];

export { menuData };
