import React from "react";

// Image
import hr from "assets/images/auth/agusto-portal-front.png";
import logo from "assets/images/auth/auth-logo.png";
import logoLight from "assets/images/logo-light.png";
import modern from "assets/images/auth-modern.png";

import { Link } from "react-router-dom";

const Modern = () => {
  return (
    <React.Fragment>
      <div className="relative z-10 flex items-center justify-center min-h-screen px-10 text-center grow py-14">
        <div>
          <Link to="/">
            <img src={logo} alt="" className="block mx-auto mb-14 h-10" />
          </Link>
          <img
            src={hr}
            alt=""
            className="block object-cover mx-auto shadow-lg md:max-w-md rounded-xl shadow-custom-800"
          />
          <div className="mt-10 text-center">
            <h3 className="mt-4 mb-3 capitalize text-custom-50">
              Human Resource Hub
            </h3>
            <p className="max-w-2xl text-custom-300 text-16">
              Agusto & Co. is the foremost Pan African Credit Rating Agency and
              a leading provider of industry research.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modern;