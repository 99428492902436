import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Flatpickr from 'react-flatpickr';
import TableContainer from 'Common/TableContainer';
import CountUp from 'react-countup';
import { Link, useParams } from 'react-router-dom';
import Collapse from "Common/Components/Collapse/Collapse";
import { ChevronDown, ChevronUp } from "lucide-react";
import Select from 'react-select';

// Icons
import { Search, Check, X, Clock, XOctagon, RefreshCw } from 'lucide-react';

// Image
import user3 from "assets/images/users/user-3.jpg";

// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
    getAttendance as onGetAttendance,
    getAppraisalDetails as onGetAppraisalDetails,
    getUserAppraisals as onGetUserAppraisals,
    createAppraisal as onCreateAppraisal,
    updateAppraisal as onUpdateAppraisal,
    deleteAppraisal as onDeleteAppraisal,
    getActiveAppraisalCategories as onGetActiveAppraisalCategories,
    checkOpenedAppraisalCategories as onCheckOpenedAppraisalCategories
  } from "slices/thunk";
import filterDataBySearch from 'Common/filterDataBySearch';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

const AppraisalDetails = () => {

    const { id } = useParams(); 
    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.HRManagment,
        (state) => ({
            dataList: state.appraisalDetails
        })
    );

    const { dataList } = useSelector(selectDataList);
    const [data, setData] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();
    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    // Get Data
    useEffect(() => {
        dispatch(onGetAppraisalDetails(id));
    }, [dispatch]);

    useEffect(() => {
        setData(dataList?.data);
    }, [dataList]);

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['day', 'date', 'checkIn', 'checkOut', 'mealBreak', 'workHours', 'overtime'];
        filterDataBySearch(dataList, search, keysToSearch, setData);
    };

    const columns = useMemo(() => [
        {
            header: "Date",
            accessorKey: "date",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <React.Fragment>
                    {cell.getValue()} <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-white border-slate-400 text-slate-500 dark:bg-zink-700 dark:border-zink-400 dark:text-zink-200 ltr:ml-1 rtl:mr-1 align-middle">{cell.row.original.day}</span>
                </React.Fragment>
            ),
        },
        {
            header: "Check In",
            accessorKey: "checkIn",
            enableColumnFilter: false
        },
        {
            header: "Check Out",
            accessorKey: "checkOut",
            enableColumnFilter: false,
        },
        {
            header: "Meal Break",
            accessorKey: "mealBreak",
            enableColumnFilter: false,
        },
        {
            header: "Work Hours",
            accessorKey: "workHours",
            enableColumnFilter: false,
        },
        {
            header: "Overtime",
            accessorKey: "overtime",
            enableColumnFilter: false,
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div className="flex gap-2">
                    <Link to="#!" className="flex items-center justify-center size-8 text-green-500 transition-all duration-200 ease-linear bg-green-100 rounded-md hover:text-white hover:bg-green-500 dark:bg-green-500/20 dark:hover:bg-green-500"><Check className="size-4" /></Link>
                    <Link to="#!" className="flex items-center justify-center size-8 text-red-500 transition-all duration-200 ease-linear bg-red-100 rounded-md hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"><X className="size-4" /></Link>
                </div>
            ),
        }
    ], []
    );

    const options = [
        { value: '', label: 'Select Employee' },
        { value: 'Willie Torres', label: 'Willie Torres' },
        { value: 'Patricia Garcia', label: 'Patricia Garcia' },
        { value: 'Juliette Fecteau', label: 'Juliette Fecteau' },
        { value: 'Thomas Hatfield', label: 'Thomas Hatfield' },
        { value: 'Juliette Fecteau', label: 'Juliette Fecteau' },
        { value: 'Nancy Reynolds', label: 'Nancy Reynolds' },
        { value: 'Holly Kavanaugh', label: 'Holly Kavanaugh' },
        { value: 'Jonas Frederiksen', label: 'Jonas Frederiksen' },
    ];

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);
  
    // Delete Data
    const onClickDelete = (cell: any) => {
      setDeleteModal(true);
      if (cell.id) {
        setEventData(cell);
      }
    };
  
    const handleDelete = () => {
      if (eventData) {
        dispatch(onDeleteAppraisal(eventData.id));
        setDeleteModal(false);
      }
    };
  
    // Update Data
    const handleUpdateDataClick = (ele: any) => {
      setEventData({ ...ele });
      setIsEdit(true);
      setShow(true);
    };

      // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
        category_id: (eventData && eventData.name) || "",
        goals: (eventData && eventData.item) || "",
        achivements: (eventData && eventData.item) || "",
        challenges: (eventData && eventData.description) || "",
        status: (eventData && eventData.status) || "",
        },
        validationSchema: Yup.object({
        category_id: Yup.string().required("Please Select Your Appraisal Category"),
        goals: Yup.string().required("Please state the goals for the period"),
        achivements: Yup.string().required("Please state the goals achieved for the period"),
        challenges: Yup.string().required("Please state the challengies"),
        status: Yup.string()
        }),

        onSubmit: (values) => {
        if (isEdit) {
            const updateData = {
            id: eventData ? eventData.id : 0,
            ...values,
            };
            // update user
            dispatch(onUpdateAppraisal(updateData));
        } else {
            const newData = {
            ...values,
            id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
            userId:
                "#TW15000" +
                (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
            };
            // save new user
            dispatch(onCreateAppraisal(newData));
        }
        toggle();
        },
    });

    const toggle = useCallback(() => {
        if (show) {
          setShow(false);
          setEventData("");
          setIsEdit(false);
        } else {
          setShow(true);
          setEventData("");
          validation.resetForm();
        }
      }, [show, validation]);

    return (
        <React.Fragment>
            <BreadCrumb title='Performance' pageTitle='Appraisal Details' />
            <div className="grid grid-cols-1 lg:grid-cols-12 xl:grid-cols-12 gap-x-5">
                <div className="lg:col-span-12 xl:col-span-3 xl:row-span-2">
                    <div className="mb-5">
                        <label htmlFor="deliveryStatusSelect" className="inline-block mb-2 text-base font-medium">Select Category</label>
                        <Select
                            className="border-slate-200 focus:outline-none focus:border-custom-500"
                            options={options}
                            isSearchable={false} // To disable search
                            name="deliveryStatusSelect"
                            id="deliveryStatusSelect"
                        />
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="text-center">
                                <div className="size-20 mx-auto rounded-full bg-slate-100 dark:bg-zink-600">
                                    <img src={data?.employee_image} alt="" className="h-20 rounded-full" />
                                </div>
                                <h6 className="mt-3 mb-1 text-16"><Link to="#!">{data?.employee_name}</Link></h6>
                                <p className="text-slate-500 dark:text-zink-200">{data?.employee_role}</p>
                            </div>
                            <div className="mt-5 overflow-x-auto">
                                <table className="w-full mb-0">
                                    <tbody>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Status</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.status}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Appraisal ID</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">#{data?.ref_no}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Period</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.appraisal_category?.month}, {data?.appraisal_category?.year}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Created At</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.created_at}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Supervisor</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.supervisor_name}</td>
                                        </tr>

                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">People Relation</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.people_relation}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Character Traits</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.character_traits}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Effective Communication</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.effective_comm}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Work Habits</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.work_habits}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">OSDQ</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.osdq}</td>
                                        </tr>
                                        <tr >
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Remark</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{data?.remark}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-4 xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-sky-500 bg-sky-100 text-15 dark:bg-sky-500/20 shrink-0"><Clock /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={0} className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Total Appraisers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-4 xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 text-red-500 bg-red-100 rounded-md text-15 dark:bg-red-500/20 shrink-0"><XOctagon /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={0} className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Completed Appraisers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-4 xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 text-yellow-500 bg-yellow-100 rounded-md text-15 dark:bg-yellow-500/20 shrink-0"><RefreshCw /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={data?.total_scores} className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Appraisal Score</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-9 lg:col-span-12">

                    <div className="grid grid-cols-2 2xl:grid-cols-5 gap-x-5">
                        <div>
                            <div className="card">
                                <div className="text-center card-body">
                                    <h6 className="mb-1 underline">0.0%</h6>
                                    <p className="uppercase text-slate-500 dark:text-zink-200">People Relations</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <div className="text-center card-body">
                                    <h6 className="mb-1">0.0%</h6>
                                    <p className="uppercase text-slate-500 dark:text-zink-200">Char traits</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <div className="text-center card-body">
                                    <h6 className="mb-1">0.0%</h6>
                                    <p className="uppercase text-slate-500 dark:text-zink-200">Eff of comm.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <div className="text-center card-body">
                                    <h6 className="mb-1">0.0%</h6>
                                    <p className="uppercase text-slate-500 dark:text-zink-200">Work habits</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="card">
                                <div className="text-center card-body">
                                    <h6 className="mb-1">0.0%</h6>
                                    <p className="uppercase text-slate-500 dark:text-zink-200">OSDQ</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">

                            <div className="grid grid-cols-1 gap-5">

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        Self Assesement
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <form
                                                action="#!"
                                                onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                                }}
                                            >
                                                <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">

                                                <div className="xl:col-span-12">
                                                    <label htmlFor="goalInput" className="inline-block mb-2 text-base font-medium">Goals to Achieve</label>
                                                    <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                    id="goalInput" name="goals" placeholder="State the goals for the period"
                                                    value={validation.values.goals}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    rows={4}>
                                                    </textarea>
                                                    {validation.touched.goals && validation.errors.goals ? <div id="goals" className="text-red-500">{validation.errors.goals}</div> : null }
                                                </div>
                                                
                                                <div className="xl:col-span-12">
                                                    <label htmlFor="goalAchievedInput" className="inline-block mb-2 text-base font-medium">Goals Achieved</label>
                                                    <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                    id="goalAchievedInput" name="achivements" placeholder="State the goals achieved for the period"
                                                    value={validation.values.achivements}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    rows={4}>
                                                    </textarea>
                                                    {validation.touched.achivements && validation.errors.achivements ? <div id="achivements" className="text-red-500">{validation.errors.achivements}</div> : null }
                                                </div>

                                                <div className="xl:col-span-12">
                                                    <label htmlFor="goalChallengesInput" className="inline-block mb-2 text-base font-medium">Challenges</label>
                                                    <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                    id="goalChallengesInput" name="challenges" placeholder="State the challengies"
                                                    value={validation.values.challenges}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    rows={4}>
                                                    </textarea>
                                                    {validation.touched.challenges && validation.errors.challenges ? <div id="challenges" className="text-red-500">{validation.errors.challenges}</div> : null }
                                                </div>

                                                {isEdit && (
                                                    <div className="xl:col-span-12">
                                                        <label htmlFor="statusInput" className="inline-block mb-2 text-base font-medium">Status</label>
                                                        <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                                                            id="statusInput"
                                                            name="status"
                                                            onChange={validation.handleChange}
                                                            value={validation.values.status || ""}
                                                        >
                                                            <option value="">Select Status</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="active">Active</option>
                                                            <option value="inactive">Inactive</option>
                                                        </select>
                                                        {validation.touched.status && validation.errors.status ? (
                                                            <p className="text-red-400">{validation.errors.status}</p>
                                                        ) : null}
                                                    </div>
                                                )}

                                                </div>

                                                <div className="flex justify-end gap-2 mt-4">
                                                <button
                                                    type="reset"
                                                    data-modal-close="addAppraisalModal"
                                                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                                                    onClick={toggle}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                                >
                                                    {!!isEdit ? "Update" : "Submit Apraisal"}
                                                </button>
                                                </div>
                                            </form>                                       
                                        </div>
                                    </Collapse.Content>
                                </Collapse>

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        People Relations
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <p>For that very reason, I went on a quest and spoke to many different professional graphic designers and asked them what graphic design tips they live. You've probably heard that opposites attract. The same is true for fonts. Don't be afraid to combine font styles that are different but complementary, like sans serif with serif, short with tall, or decorative with simple. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco.</p>
                                        </div>
                                    </Collapse.Content>
                                </Collapse>

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        Character Traits
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <p>For that very reason, I went on a quest and spoke to many different professional graphic designers and asked them what graphic design tips they live. You've probably heard that opposites attract. The same is true for fonts. Don't be afraid to combine font styles that are different but complementary, like sans serif with serif, short with tall, or decorative with simple. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco.</p>
                                        </div>
                                    </Collapse.Content>
                                </Collapse>

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    Effectiveness of communication
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <p>For that very reason, I went on a quest and spoke to many different professional graphic designers and asked them what graphic design tips they live. You've probably heard that opposites attract. The same is true for fonts. Don't be afraid to combine font styles that are different but complementary, like sans serif with serif, short with tall, or decorative with simple. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco.</p>
                                        </div>
                                    </Collapse.Content>
                                </Collapse>

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    Work Habits
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <p>For that very reason, I went on a quest and spoke to many different professional graphic designers and asked them what graphic design tips they live. You've probably heard that opposites attract. The same is true for fonts. Don't be afraid to combine font styles that are different but complementary, like sans serif with serif, short with tall, or decorative with simple. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco.</p>
                                        </div>
                                    </Collapse.Content>
                                </Collapse>

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    Operational Service Delivery Quality
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <p>For that very reason, I went on a quest and spoke to many different professional graphic designers and asked them what graphic design tips they live. You've probably heard that opposites attract. The same is true for fonts. Don't be afraid to combine font styles that are different but complementary, like sans serif with serif, short with tall, or decorative with simple. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco.</p>
                                        </div>
                                    </Collapse.Content>
                                </Collapse>

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    Remark
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <p>For that very reason, I went on a quest and spoke to many different professional graphic designers and asked them what graphic design tips they live. You've probably heard that opposites attract. The same is true for fonts. Don't be afraid to combine font styles that are different but complementary, like sans serif with serif, short with tall, or decorative with simple. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco.</p>
                                        </div>
                                    </Collapse.Content>
                                </Collapse>

                                <Collapse>
                                    <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    Add your Comment
                                        <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                        </div>
                                    </Collapse.Trigger>
                                    <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                        <div className="card-body">
                                            <p>For that very reason, I went on a quest and spoke to many different professional graphic designers and asked them what graphic design tips they live. You've probably heard that opposites attract. The same is true for fonts. Don't be afraid to combine font styles that are different but complementary, like sans serif with serif, short with tall, or decorative with simple. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco.</p>
                                        </div>
                                    </Collapse.Content>
                                </Collapse>
                            </div>

                            {/* <div className="grid grid-cols-1 gap-4 mb-5 lg:grid-cols-2 xl:grid-cols-12">
                                <div className="xl:col-span-3">
                                    <div className="relative">
                                        <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for ..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                                        <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                                    </div>
                                </div>
                                <div className="xl:col-span-3">
                                    <div>
                                        <Flatpickr
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={{
                                                dateFormat: "d M, Y",
                                                mode: "range",
                                            }}
                                            placeholder='Select date'
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end gap-2 text-right lg:col-span-2 xl:col-span-4 xl:col-start-10">
                                    <Link to="#!" type="button" className="text-red-500 bg-white border-red-500 border-dashed btn hover:text-red-500 hover:bg-red-50 hover:border-red-600 focus:text-red-600 focus:bg-red-50 focus:border-red-600 active:text-red-600 active:bg-red-50 active:border-red-600 dark:bg-zink-700 dark:ring-red-400/20 dark:hover:bg-red-800/20 dark:focus:bg-red-800/20 dark:active:bg-red-800/20">Rejecte All</Link>
                                    <Link to="#!" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Approve All</Link>
                                </div>
                            </div>
                            {data && data.length > 0 ?
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(data || [])}
                                    customPageSize={10}
                                    divclassName="overflow-x-auto"
                                    tableclassName="w-full whitespace-nowrap"
                                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                                    tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                                    PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                                />
                                :
                                (<div className="noresult">
                                    <div className="py-6 text-center">
                                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                        <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched more than 10+ Attendance We did not find any Attendance for you search.</p>
                                    </div>
                                </div>)} */}

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AppraisalDetails;
