import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from './AuthContext';

interface AuthProtectedProps {
  children: ReactNode;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {

  const authUser: string | null = localStorage.getItem('authUser');
  const token = authUser ? JSON.parse(authUser).token : null;
  if (token) {
    const payload = token.split('.')[1];

    if (payload) {
        // const decoded = JSON.parse(atob(payload));
        // const exp = decoded.exp * 1000;

        try {
            const decoded = JSON.parse(atob(payload));
            const exp = decoded.exp * 1000;

            if (exp > Date.now()) {
                // setIsAuthenticated(true);
            } else {
                localStorage.removeItem('authUser');
            }
        } catch (error) {
            console.error("Token decoding failed:", error);
            localStorage.removeItem('authUser'); // Cleanup in case of failure
        }

    }
  }


  // if (!isAuthenticated) {
  //   return <Navigate to={{ pathname: "/login" }} />
  // }
  // return <>{children}</>;

  if (!localStorage.getItem("authUser")) {
    return <Navigate to={{ pathname: "/logout" }} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;

