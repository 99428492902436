import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import BreadCrumb from 'Common/BreadCrumb';
import Widgets from './Widgets';
import EmployeePerformance from './EmployeePerformance';
import UpcomingScheduled from './UpcomingScheduled';
import TotalProjects from './TotalProjects';
import UpcomingInterview from './UpcomingInterview';
import RecentPayroll from './RecentPayroll';
import {
    getUserList as onGetUserList,
    getUserProfile as onGetUserProfile,
    getUserMetrix as onGetUserMetrix,
    getUsersBirthdays as onGetUsersBirthdays,
    getUsersAnivarsaries as onGetUsersAnivarsaries,
    getUsersCelebrations as onGetUsersCelebrations,
    getUserCelebrationMetrix as onGetUserCelebrationMetrix,
} from 'slices/users/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const HRDashboard = () => {

    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.Users,
        (user) => ({
            userList: user.userList,
            profileList: user.userProfile,
            userMetrix: user.userMetrix,
            userBirthdays: user.userBirthdays,
            userAnivarsaries: user.userAnivarsaries,
            userCelebrations: user.userCelebrations,
            userCelebrationMetrix: user.userCelebrationMetrix,
        })
    );
    
    const { userList, userMetrix, profileList} = useSelector(selectDataList);
    const [users, setUser] = useState<any>([]);
    const [user, setProfile] = useState<any>([]);
    const [metrix, setMetrix] = useState<any>([]);
    // Get Data
    useEffect(() => {
        dispatch(onGetUserList());
        dispatch(onGetUserMetrix());
        dispatch(onGetUsersBirthdays());
        dispatch(onGetUsersAnivarsaries());
        dispatch(onGetUserCelebrationMetrix());
        dispatch(onGetUsersCelebrations());  
    }, [dispatch]);

    useEffect(() => {
        setUser(userList?.data);
        setProfile(profileList?.data);
        setMetrix(userMetrix?.data);
    }, [userList, userMetrix]);

    return (
        <React.Fragment>
            <BreadCrumb title='HR' pageTitle='Dashboards' />
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
                    <h5 className="mb-2">Welcome {user?.username}</h5>
                    <p className="mb-5 text-slate-500 dark:text-zink-200">
                        Syncing recent updates
                        {/* <Link to="#" className="underline text-slate-800 dark:text-zink-50">Glennie Langosh</Link> is pending since 05 Dec, 2023. the documentation of the tasks, workflows, and activities that make up a process managed by the HR or People Ops team. <Link to="#!" className="text-red-500">Learn More</Link> */}
                    </p>
                </div>
                <div className="col-span-12 md:order-2 xl:col-span-4 2xl:col-start-9 card">
                    <div className="p-4">
                        <div className="grid grid-cols-3">
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={metrix?.total} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Total</p>
                            </div>
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={metrix?.male} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Male</p>
                            </div>
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={metrix?.female} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Female</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Widgets />
                <EmployeePerformance />
                <UpcomingScheduled />
                {/* <TotalProjects />
                <UpcomingInterview />
                <RecentPayroll /> */}

            </div>
        </React.Fragment>
    );
};

export default HRDashboard;
