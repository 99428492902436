import React from 'react';
import { Router, Route, Routes } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import Layout from 'Layout';
import NonAuthLayout from "Layout/NonLayout"
import { AuthProvider } from './AuthContext';
import AuthProtected from './AuthProtected';

const RouteIndex = () => {
  return (
    <React.Fragment>

      <AuthProvider>
        <Routes>
          {authProtectedRoutes.map((route: any, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <AuthProtected>
                  <Layout>
                    <route.component />
                  </Layout>
                </AuthProtected>
              }
            />
          ))}
          {publicRoutes.map((route: any, idx: number) => (
            <Route
              path={route.path}
              key={idx}
              element={
                <NonAuthLayout>
                  <route.component />
                </NonAuthLayout>
              } />
          ))}
        </Routes>
      </AuthProvider>

    </React.Fragment>
  );
};

export default RouteIndex;
