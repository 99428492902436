import React, {ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import {MoveLeft, MoveRight, Gem, CheckCircle, LucideMessageCircleWarning, Loader2, LogIn, UploadCloud, PartyPopper, Rocket, User2, BadgeCheck, Dribbble, Facebook, Github, Globe, ImagePlus, Instagram, Linkedin, Mail, MapPin, MoreHorizontal, UserCircle } from "lucide-react";
import Tab from "Common/Components/Tab/Tab";
import { Nav } from "Common/Components/Tab/Nav";
import { Dropdown } from "Common/Components/Dropdown";
import Dropzone from "react-dropzone"
import Alert from "Common/Components/Alert";
import Modal from 'Common/Components/Modal';
// react-redux
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import {
    getUserList as onGetUserList,
    getUserDetails as ongetUserDetails,
    addUserList as onAddUserList,
    updateUserList as onUpdateUserList,
    deleteUserList as onDeleteUserList
} from 'slices/users/thunk';

import {
    getActivePositions as onGetActivePositions,
    getActiveUserCategory as onGetActiveUserCategory,
    getActiveRoles as onGetActiveRoles,
    addUserList as onaddUser,
    deletePosition as onDeletePosition,
    uploadMultiPartFiles as onUploadMultiPartFiles,
    getActiveDepartments as onGetActiveDepartments,
} from 'slices/thunk';

// IMage
import avatar1 from "assets/images/users/avatar-1.png";

// Images
import dummyImg from "assets/images/users/user-dummy-img.jpg";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import moment from "moment";

const AccountInfo = ({ className }: any) => {

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const selectDataList = createSelector(
        (state: any) => state.Users,
        (hrState: any) => hrState.HRManagment,
        (state, hrState) => ({
            dataList: state.userDetail,
            userList: state.userList,
            positionList: hrState.activePositionlist,
            categoryList: hrState.activeUserCategorylist,
            departmentList: hrState.activedepartmentslist,
            roleList: hrState.activeRoleslist
        })
    );

    const {dataList, userList, positionList, categoryList, departmentList, roleList } = useSelector(selectDataList);
    const [user, setData] = useState<any>({});
    const [submitedForm, setSubmitedForm] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [users, setUser] = useState<any>([]);
    const [positions, setPositions] = useState<any>([]);
    const [roles, setRole] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();
    const [departments, setDepartments] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    useEffect(() => {
        dispatch(onGetUserList());
        dispatch(onGetActivePositions());
        dispatch(onGetActiveDepartments());
        dispatch(onGetActiveRoles());
    }, [dispatch]);

    useEffect(() => {
        setRole(roleList?.data);
        setUser(userList?.data);
        setData(dataList?.data);
        setPositions(positionList?.data);
        setDepartments(departmentList?.data);
    }, [dataList, positionList, categoryList, departmentList, roleList, userList]);

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };


    // Update Data
    const handleUpdateDataClick = (data: any) => {
        console.log('data', data);
        console.log('roles', roles);
        setEventData({ ...data });
        setIsEdit(true);
        setShow(true);
        validation.setFieldValue('isActived', data.status === 'active' ? true : false);
    };

    // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstname: (eventData && eventData.firstname) || "",
            lastname: (eventData && eventData.lastname) || "",
            othernames: (eventData && eventData.othernames) || "",
            mobile_numbers: (eventData && eventData.mobile_numbers) || "",
            dob: (eventData && moment(eventData.dob)) || "",
            gender: (eventData && eventData.gender) || "",
            img: (eventData && eventData.image) || "",
            image: "",
            contact_address: (eventData && eventData.contact_address) || "",
            corporate_email: (eventData && eventData.corporate_email) || "",
            personal_email: (eventData && eventData.personal_email) || "",
            password: "",
            confirm_password: "",
            employment_date: (eventData && eventData.employment_date) || "",

            class_of_degree: (eventData && eventData.class_of_degree) || "",
            course_of_study: (eventData && eventData.course_of_study) || "",
            other_degrees: (eventData && eventData.other_degrees) || "",
            pro_certifications: (eventData && eventData.pro_certifications) || "",
            university_attended: (eventData && eventData.university_attended) || "",

            NOK_address: (eventData && eventData.NOK_address) || "",
            NOK_email: (eventData && eventData.NOK_email) || "",
            NOK_fullname: (eventData && eventData.NOK_fullname) || "",
            NOK_phone: (eventData && eventData.NOK_phone) || "",
            NOK_relationship: (eventData && eventData.NOK_relationship) || "",

            entitled_leave_days: ''+(eventData && eventData.entitled_leave_days) || "",
            leave_carried_over: ''+(eventData && eventData.leave_carried_over) || "",
            taken_leave_days: ''+(eventData && eventData.taken_leave_days) || "",

            employee_id: (eventData && eventData.employee_id) || "",
            supervisor_id: (eventData && eventData.supervisor_id) || "",
            position_id: (eventData && eventData.position_id) || "",
            role_id: (eventData && eventData.role_id) || "",
            two_factor: (eventData && eventData.two_factor) || false,
            verify_email: (eventData && eventData.verify_email) || false,
            isActived: (user && user?.status === 'active') || false,
            isConfirmed: (eventData && eventData.isConfirmed) || false,
            isAdmin: (eventData && eventData.isAdmin) || false,

            user_category_id: (eventData && eventData.user_category_id) || "",
            department_id: (eventData && eventData.department_id) || "",
            task_number: (eventData && eventData.task_number) || "",
            pension_provider: (eventData && eventData.pension_provider) || "",
            pension_number: (eventData && eventData.pension_number) || "",
            documents: (eventData && eventData.documents) || "",
        },

        validationSchema: Yup.object().shape({
            firstname: Yup.string(),
            lastname: Yup.string(),
            othernames: Yup.string(),
            mobile_numbers: Yup.string(),
            // mobile_numbers: Yup.number()
            //                 .typeError("That doesn't look like a phone number")
            //                 .positive("A phone number can't start with a minus")
            //                 .integer("A phone number can't include a decimal point")
            //                 .min(8),

            dob: Yup.string(),
            gender: Yup.string(),
            image: Yup.string(),
            img: Yup.string(),
            contact_address: Yup.string(),
            // corporate_email: Yup.string().email("Please enter a valid email").required("Email is required."),
            personal_email: Yup.string(),
            corporate_email: Yup.string(),
            password: Yup.string(),
            confirm_password: Yup.string(),
            class_of_degree: Yup.string(),
            course_of_study: Yup.string(),
            other_degrees: Yup.string(),
            pro_certifications: Yup.string(),
            university_attended: Yup.string(),
            NOK_address: Yup.string(),
            NOK_email: Yup.string(),
            NOK_fullname: Yup.string(),
            NOK_phone: Yup.string(),
            NOK_relationship: Yup.string(),

            employment_date: Yup.string(),
            employee_id: Yup.string(),

            supervisor_id: Yup.string(),
            position_id: Yup.string(),
            role_id: Yup.string(),
            two_factor: Yup.boolean(),
            verify_email: Yup.boolean(),
            isActived: Yup.boolean(),
            isConfirmed: Yup.boolean(),
            isAdmin: Yup.boolean(),

            entitled_leave_days: Yup.string(),
            leave_carried_over: Yup.string(),
            taken_leave_days: Yup.string(),
            
            user_category_id: Yup.string(),
            department_id: Yup.string(),
            task_number: Yup.string(),
            pension_provider: Yup.string(),
            pension_number: Yup.string(),

            documents: Yup.string(),

        }),

        onSubmit: (values) => {
            if (isEdit) {
                const updateUser = {
                    id: eventData ? eventData.id : 0,
                    ...values,
                };
                // update user
                dispatch(onUpdateUserList(updateUser));
            } else {
                const newUser = {
                    ...values,
                    id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    employee_id: "#TW15000" + (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                };
                // save new user
                dispatch(onAddUserList(newUser));
            }
            toggle();
        },
    });

    // Image
    // const [selectedImage, setSelectedImage] = useState<any>();
    const [selectedImage, setSelectedImage] = React.useState<string | ArrayBuffer | null>(avatar1);
    const handleImageChange = (event: any) => {
        const fileInput = event.target;
        if (fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            const reader = new FileReader();
            reader.onload = (e: any) => {
                validation.setFieldValue('img', e.target.result);
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const [selectedFiles, setSelectedFiles] = React.useState<any>([]);

    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setSelectedFiles(files)
    }

    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData("");
            setIsEdit(false);
            setSelectedImage('');
        } else {
            setShow(true);
            setEventData("");
            setSelectedImage('');
            validation.resetForm();
        }
    }, [show, validation]);

    return (
        <React.Fragment>
            <div className={className}>
                <div className="grid grid-cols-1 gap-5 lg:grid-cols-12 2xl:grid-cols-12">
                    <div className="lg:col-span-2 2xl:col-span-1">
                        <div className="relative inline-block size-20 rounded-full shadow-md bg-slate-100 profile-user xl:size-28">
                            {/* <img src={selectedImage?.toString() ?? user.image} alt="" className="object-cover border-0 rounded-full img-thumbnail user-profile-image" /> */}
                            <img src={user ? user.image : avatar1} alt="" className="object-cover border-0 rounded-full img-thumbnail user-profile-image" />
                            <div className="absolute bottom-0 flex items-center justify-center size-8 rounded-full ltr:right-0 rtl:left-0 profile-photo-edit">
                                <input id="profile-img-file-input" type="file"
                                    className="hidden profile-img-file-input"
                                    onChange={handleImageChange} />
                                <label htmlFor="profile-img-file-input" className="flex items-center justify-center size-8 bg-white rounded-full shadow-lg cursor-pointer dark:bg-zink-600 profile-photo-edit">
                                    <ImagePlus className="size-4 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></ImagePlus>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-10 2xl:col-span-9">
                        <h5 className="mb-1">{user ? user.username : 'NA'} - {user ? user.employee_id : 'NA'} <BadgeCheck className={`inline-block size-4 ${user?.isConfirmed ? 'text-green-500 fill-green-100' : 'text-red-500 fill-red-100'} dark:fill-custom-500/20`}></BadgeCheck></h5>
                        <div className="flex gap-3 mb-2">
                            <p className="text-slate-500 dark:text-zink-200"><UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle> {user?.role ? user.role.name : 'NA'}</p>
                            <p className="text-slate-500 dark:text-zink-200"><UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle> {user?.role ? user.department.name : 'NA'}</p>
                            <p className="text-slate-500 dark:text-zink-200"><UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle> {user?.position ? user?.position.name : 'NA'} - {user?.position ? user?.position.user_category.name : 'NA'} </p>
                        </div>

                        <div className="flex gap-3 mb-4">
                            <p className="text-slate-500 dark:text-zink-200"><MapPin className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></MapPin> {user ? user.contact_address : 'NA'}</p>
                        </div>

                        <ul className="flex flex-wrap gap-3 mt-4 text-center divide-x divide-slate-200 dark:divide-zink-500 rtl:divide-x-reverse">
                            <li className="px-5">
                                <h5>{user ? user.entitled_leave_days : 0}</h5>
                                <p className="text-slate-500 dark:text-zink-200">Entitled Leave</p>
                            </li>
                            <li className="px-5">
                                <h5>{user ? user.leave_carried_over : 0}</h5>
                                <p className="text-slate-500 dark:text-zink-200">Leave carried over</p>
                            </li>

                            <li className="px-5">
                                <h5>{user ? user.total_leave_days : 'NA'}</h5>
                                <p className="text-slate-500 dark:text-zink-200">Total No. vacation</p>
                            </li>
                            <li className="px-5">
                                <h5>{user ? user.taken_leave_days : 0}</h5>
                                <p className="text-slate-500 dark:text-zink-200">Leave Taken</p>
                            </li>
                            <li className="px-5">
                                <h5>{user ? user.pending_leave_days : 0}</h5>
                                <p className="text-slate-500 dark:text-zink-200">Leave Pending</p>
                            </li>
                        </ul>
                        <p className="mt-4 text-slate-500 dark:text-zink-200">{user?.role ? user.role.description : 'NA'} {user?.role ? user.department.description : 'NA'}</p>
                        <div className="flex gap-2 mt-4">

                        {user?.status === 'active' ? <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-green-500 border-green-500 text-green-50">Active</span>
                        : user?.status === 'inactive' ? <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">Inactive</span> 
                        : <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-yellow-500 border-yellow-500 text-yellow-50">Pending</span> }


                            {/* <a href="#!" className="flex items-center justify-center transition-all duration-200 ease-linear rounded size-9 text-sky-500 bg-sky-100 hover:bg-sky-200 dark:bg-sky-500/20 dark:hover:bg-sky-500/30">
                                <Facebook className="size-4"></Facebook>
                            </a>
                            <a href="#!" className="flex items-center justify-center text-pink-500 transition-all duration-200 ease-linear bg-pink-100 rounded size-9 hover:bg-pink-200 dark:bg-pink-500/20 dark:hover:bg-pink-500/30">
                                <Instagram className="size-4"></Instagram>
                            </a>
                            <a href="#!" className="flex items-center justify-center text-red-500 transition-all duration-200 ease-linear bg-red-100 rounded size-9 hover:bg-red-200 dark:bg-red-500/20 dark:hover:bg-red-500/30">
                                <Globe className="size-4"></Globe>
                            </a>
                            <a href="#!" className="flex items-center justify-center transition-all duration-200 ease-linear rounded text-custom-500 bg-custom-100 size-9 hover:bg-custom-200 dark:bg-custom-500/20 dark:hover:bg-custom-500/30">
                                <Linkedin className="size-4"></Linkedin>
                            </a>
                            <a href="#!" className="flex items-center justify-center text-pink-500 transition-all duration-200 ease-linear bg-pink-100 rounded size-9 hover:bg-pink-200 dark:bg-pink-500/20 dark:hover:bg-pink-500/30">
                                <Dribbble className="size-4"></Dribbble>
                            </a>
                            <a href="#!" className="flex items-center justify-center transition-all duration-200 ease-linear rounded size-9 text-slate-500 bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500">
                                <Github className="size-4"></Github>
                            </a> */}
                        </div>
                    </div>
                    <div className="lg:col-span-12 2xl:col-span-2">
                        <div className="flex gap-2 2xl:justify-end">

                            <a href="mailto:themesdesign@gmail.com" className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20">
                                <Mail className="size-4"></Mail>
                            </a>
                            <button type="button"
                                onClick={() => {
                                const data = dataList?.data;
                                handleUpdateDataClick(data);
                              }}
                              className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Edit</button>

                            <Dropdown className="relative">
                                <Dropdown.Trigger className="flex items-center justify-center size-[37.5px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20" id="accountSettings" data-bs-toggle="dropdown">
                                    <MoreHorizontal className="size-4"></MoreHorizontal>
                                </Dropdown.Trigger>
                                <Dropdown.Content placement="right-end" className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white dark:bg-zink-600 rounded-md shadow-md dropdown-menu min-w-[10rem]" aria-labelledby="accountSettings">
                                    <li className="px-3 mb-2 text-sm text-slate-500">
                                        Payments
                                    </li>
                                    <li>
                                        <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" href="#!">Create Invoice</a>
                                    </li>
                                    <li>
                                        <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" href="#!">Pending Billing</a>
                                    </li>
                                    <li>
                                        <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" href="#!">Genarate Bill</a>
                                    </li>
                                    <li>
                                        <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" href="#!">Subscription</a>
                                    </li>
                                </Dropdown.Content>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={toggle} id="extraLargeModal" modal-center="true"
                    className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                    dialogClassName="w-screen lg:w-[55rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full">
                    <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                        closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                        <Modal.Title className="text-16">{!!isEdit ? "Edit User" : "Add User"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">

                        <Tab.Container defaultActiveKey="personalInfo">

                            <Nav className="grid grid-cols-1 gap-3 lg:grid-cols-4 nav-tabs form-wizard">
                                <Nav.Item eventKey="personalInfo" className="group/item">
                                    <a href="#!" data-target="personalInfo" data-tab-id="1" className="block px-4 py-5 text-center rounded-md text-slate-500 bg-slate-50 dark:text-zink-200 dark:bg-zink-600 group-[.active]/item:bg-custom-500 group-[.active]/item:text-custom-50">
                                        <Rocket className="block h-6 mx-auto fill-slate-200 dark:fill-zink-400 group-[.active]/item:fill-slate-200 dark:group-[.active]/item:fill-custom-500" />
                                        <span className="block mt-3 font-medium text-15">1. Personal Info</span>
                                    </a>
                                </Nav.Item>
                                <Nav.Item eventKey="accountInfo" className="group/item">
                                    <a href="#!" data-target="accountInfo" data-tab-id="2" className="block px-4 py-5 text-center rounded-md text-slate-500 bg-slate-50 dark:text-zink-200 dark:bg-zink-600 group-[.active]/item:bg-custom-500 group-[.active]/item:text-custom-50">
                                        <User2 className="block h-6 mx-auto fill-slate-200 dark:fill-zink-400 group-[.active]/item:fill-slate-200 dark:group-[.active]/item:fill-custom-500" />
                                        <span className="block mt-3 font-medium text-15">2. Account Settings</span>
                                    </a>
                                </Nav.Item>
                                <Nav.Item eventKey="documentUploadInfo" className="group/item">
                                    <a href="#!" data-target="documentUploadInfo" data-tab-id="3" className="block px-4 py-5 text-center rounded-md text-slate-500 bg-slate-50 dark:text-zink-200 dark:bg-zink-600 group-[.active]/item:bg-custom-500 group-[.active]/item:text-custom-50">
                                        <MapPin className="block h-6 mx-auto fill-slate-200 dark:fill-zink-400 group-[.active]/item:fill-slate-200 dark:group-[.active]/item:fill-custom-500" />
                                        <span className="block mt-3 font-medium text-15">3. Security</span>
                                    </a>
                                </Nav.Item>
                                <Nav.Item eventKey="completed" className="group/item">
                                    <a href="#!" data-target="completed" data-tab-id="4" className="block px-4 py-5 text-center rounded-md text-slate-500 bg-slate-50 dark:text-zink-200 dark:bg-zink-600 group-[.active]/item:bg-custom-500 group-[.active]/item:text-custom-50">
                                        <PartyPopper className="block h-6 mx-auto fill-slate-200 dark:fill-zink-400 group-[.active]/item:fill-slate-200 dark:group-[.active]/item:fill-custom-500" />
                                        <span className="block mt-3 font-medium text-15">4. Completed</span>
                                    </a>
                                </Nav.Item>
                            </Nav>

                            <form onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit()
                            }}>

                                <Tab.Content className="mt-5 tab-content">

                                    <Tab.Pane eventKey="personalInfo">
                                        {/* <PersonalInfo /> */}

                                        <h4 className="mb-3">Personal Info</h4>
                                            <h6 className="mb-3">Basic Info</h6>

                                            {/* User Image */}
                                            <div className="grid grid-cols-1 gap-4 mb-8 md:grid-cols-1">
                                                <label htmlFor="Address" className="inline-block mb-2 text-base font-medium">User Image</label>
                                                <div className="relative size-24 mx-auto mb-4 rounded-full shadow-md bg-slate-100 profile-user dark:bg-zink-500">
                                                    <img src={validation?.values?.img || dummyImg} alt="" className="object-cover w-full h-full rounded-full user-profile-image" />
                                                    <div className="absolute bottom-0 flex items-center justify-center size-8 rounded-full ltr:right-0 rtl:left-0 profile-photo-edit">
                                                        <input
                                                            id="profile-img-file-input"
                                                            name="image"
                                                            type="file"
                                                            accept="image/*"
                                                            className="hidden profile-img-file-input"
                                                            value={validation.values.image}
                                                            onChange={handleImageChange} />
                                                        <label htmlFor="profile-img-file-input" className="flex items-center justify-center size-8 bg-white rounded-full shadow-lg cursor-pointer dark:bg-zink-600 profile-photo-edit">
                                                            <ImagePlus className="size-4 text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500" />
                                                        </label>
                                                    </div>
                                                </div>
                                                {validation.touched.img && validation.errors.img ? (
                                                    <p className="text-red-400">{validation.errors.img}</p>
                                                ) : null}
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-3">
                                                <div>
                                                    <label htmlFor="FirstNameInput" className="inline-block mb-2 text-base font-medium">First Name <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="FirstNameInput"
                                                        name="firstname"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="First name"
                                                        value={validation.values.firstname}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.firstname && validation.errors.firstname ?
                                                            <div className="text-red-500">{validation.errors.firstname}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="lastNameInput" className="inline-block mb-2 text-base font-medium">Last Name <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="lastNameInput"
                                                        name="lastname"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Last name"
                                                        value={validation.values.lastname}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.lastname && validation.errors.lastname ?
                                                            <div id="lastName" className="text-red-500">{validation.errors.lastname}</div>
                                                            : null
                                                    }
                                                    <div className="text-red-500"></div>
                                                </div>

                                                <div>
                                                    <label htmlFor="otherNameInput" className="inline-block mb-2 text-base font-medium">Other Name <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="otherNameInput"
                                                        name="othernames"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Last name"
                                                        value={validation.values.othernames}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.othernames && validation.errors.othernames ?
                                                            <div id="lastName" className="text-red-500">{validation.errors.othernames}</div>
                                                            : null
                                                    }
                                                    <div className="text-red-500"></div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-1">
                                                <div>
                                                    <label htmlFor="personalEmailInput" className="inline-block mb-2 text-base font-medium">Personal Email <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="email"
                                                        id="personalEmailInput"
                                                        name="personal_email"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Personal Email"
                                                        value={validation.values.personal_email}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.personal_email && validation.errors.personal_email ?
                                                            <div className="text-red-500">{validation.errors.personal_email}</div>
                                                            : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                                <div>
                                                    <label htmlFor="mobileNumberInput" className="inline-block mb-2 text-base font-medium">Mobile Number <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="mobileNumberInput"
                                                        name="mobile_numbers"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Mobile Numbers"
                                                        value={validation.values.mobile_numbers}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.mobile_numbers && validation.errors.mobile_numbers ?
                                                            <div id="mobile_numbers" className="text-red-500">{validation.errors.mobile_numbers}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="genderInput" className="inline-block mb-2 text-base font-medium">Gender <span className="text-red-500">*</span></label>
                                                    <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false 
                                                        id="genderInput"
                                                        name="gender"
                                                        onChange={validation.handleChange}
                                                        value={validation.values.gender || ""}
                                                    >
                                                        <option value="">Select Category</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    {
                                                        validation.touched.gender && validation.errors.gender ?
                                                            <div id="gender" className="text-red-500">{validation.errors.gender}</div>
                                                            : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                                <div>
                                                    <label htmlFor="birthDateInput" className="inline-block mb-2 text-base font-medium">Birth of Date <span className="text-red-500">*</span></label>
                                                    <input type="date" 
                                                        id="birthDateInput" 
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                        placeholder="Select date" 
                                                        name="dob"
                                                        value={validation.values.dob}
                                                        onChange={validation.handleChange}
                                                        // onChange={employeeIdChange}
                                                        onBlur={validation.handleBlur}
                                                        />
                                                    <div id="birthDateInputError" className="text-red-500"></div>
                                                </div>
                                                <div>
                                                    <label htmlFor="joiningDateInput" className="inline-block mb-2 text-base font-medium">Joining Date <span className="text-red-500">*</span></label>
                                                    <input type="date" 
                                                        id="joiningDateInput" 
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                        placeholder="Joining Date" 
                                                        name="employment_date"
                                                        value={validation.values.employment_date}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} />
                                                    <div id="joiningDateInputError" className="text-red-500"></div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-1">
                                                <label htmlFor="address" className="inline-block mb-2 text-base font-medium">Address</label>
                                                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                        id="address" 
                                                        name="contact_address"
                                                        value={validation.values.contact_address}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        rows={4}>
                                                        
                                                    </textarea>
                                                    {validation.touched.contact_address && validation.errors.contact_address ?
                                                            <div id="contact_address" className="text-red-500">{validation.errors.contact_address}</div>
                                                            : null
                                                    }
                                            </div>
                                            
                                            <h5 className="mb-3">Education Information</h5>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                                <div>
                                                    <label htmlFor="universityAttendedInput" className="inline-block mb-2 text-base font-medium">University Attended</label>
                                                    <input
                                                        type="text"
                                                        id="universityAttendedInput"
                                                        name="university_attended"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="University Attended"
                                                        value={validation.values.university_attended}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.university_attended && validation.errors.university_attended ?
                                                            <div className="text-red-500">{validation.errors.university_attended}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="courseOfStudyInput" className="inline-block mb-2 text-base font-medium">Course of Study</label>
                                                    <input
                                                        type="text"
                                                        id="courseOfStudyInput"
                                                        name="course_of_study"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Last name"
                                                        value={validation.values.course_of_study}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.course_of_study && validation.errors.course_of_study ?
                                                            <div id="course_of_study" className="text-red-500">{validation.errors.course_of_study}</div>
                                                            : null
                                                    }
                                                    <div className="text-red-500"></div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                                <div>
                                                    <label htmlFor="classOfDegreeInput" className="inline-block mb-2 text-base font-medium">Class of Degree</label>
                                                    <input
                                                        type="text"
                                                        id="classOfDegreeInput"
                                                        name="class_of_degree"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Class of Degree"
                                                        value={validation.values.class_of_degree}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.class_of_degree && validation.errors.class_of_degree ?
                                                            <div className="text-red-500">{validation.errors.class_of_degree}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="otherDegreesInput" className="inline-block mb-2 text-base font-medium">Other Degrees</label>
                                                    <input
                                                        type="text"
                                                        id="otherDegreesInput"
                                                        name="other_degrees"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Other Degrees"
                                                        value={validation.values.other_degrees}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.other_degrees && validation.errors.other_degrees ?
                                                            <div id="other_degrees" className="text-red-500">{validation.errors.other_degrees}</div>
                                                            : null
                                                    }
                                                    <div className="text-red-500"></div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-1">
                                                <div>
                                                    <label htmlFor="prof_certificateInput" className="inline-block mb-2 text-base font-medium">Profesional Certifications <span className="text-red-500">*</span></label>
                                                    <input type="text" 
                                                        id="prof_certificateInput" 
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                        placeholder="Profesional Certifications" 
                                                        name="pro_certifications"
                                                        value={validation.values.pro_certifications}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} />
                                                    <div id="prof_certificateInput" className="text-red-500"></div>
                                                </div>
                                            </div>


                                            <h5 className="mb-3">Next of Kin Information</h5>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                                <div>
                                                    <label htmlFor="NOK_fullnameInput" className="inline-block mb-2 text-base font-medium">Next of Kin Name</label>
                                                    <input
                                                        type="text"
                                                        id="NOK_fullnameInput"
                                                        name="NOK_fullname"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="NOK Name"
                                                        value={validation.values.NOK_fullname}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.NOK_fullname && validation.errors.NOK_fullname ?
                                                            <div className="text-red-500">{validation.errors.NOK_fullname}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="NOKRelationshipInput" className="inline-block mb-2 text-base font-medium">Next of Kin Relationship</label>
                                                    <input
                                                        type="text"
                                                        id="NOKRelationshipInput"
                                                        name="NOK_relationship"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Next of Kin Relationship"
                                                        value={validation.values.NOK_relationship}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.NOK_relationship && validation.errors.NOK_relationship ?
                                                            <div id="NOK_relationship" className="text-red-500">{validation.errors.NOK_relationship}</div>
                                                            : null
                                                    }
                                                    <div className="text-red-500"></div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                                <div>
                                                    <label htmlFor="nextofkinInput" className="inline-block mb-2 text-base font-medium">Next of kin Email</label>
                                                    <input
                                                        type="email"
                                                        id="nextofkinInput"
                                                        name="NOK_email"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Next of Kin Email"
                                                        value={validation.values.NOK_email}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.NOK_email && validation.errors.NOK_email ?
                                                            <div className="text-red-500">{validation.errors.NOK_email}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="NOKPhoneInput" className="inline-block mb-2 text-base font-medium">Next of kin Mobile No.</label>
                                                    <input
                                                        type="text"
                                                        id="NOKPhoneInput"
                                                        name="NOK_phone"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Other Degrees"
                                                        value={validation.values.NOK_phone}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.NOK_phone && validation.errors.NOK_phone ?
                                                            <div id="NOK_phone" className="text-red-500">{validation.errors.NOK_phone}</div>
                                                            : null
                                                    }
                                                    <div className="text-red-500"></div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-1">
                                                <label htmlFor="NOKAddress" className="inline-block mb-2 text-base font-medium">Next of Kin Address</label>
                                                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                                        id="NOKAddress" 
                                                        name="NOK_address"
                                                        value={validation.values.NOK_address}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        rows={4}>
                                                        
                                                    </textarea>
                                                    {validation.touched.NOK_address && validation.errors.NOK_address ?
                                                            <div id="NOK_address" className="text-red-500">{validation.errors.NOK_address}</div>
                                                            : null
                                                    }
                                            </div>
                                            {/* <div className="flex justify-between gap-2 mt-5">
                                                <button type="button" data-action="prev" className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50"><MoveLeft className="inline-block h-4 mr-1" /> <span className="align-middle">Previous</span></button>
                                                <button type="submit" data-action="next" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><span className="align-middle">Next</span> <MoveRight className="inline-block h-4 ml-1" /></button>
                                            </div> */}

                                    </Tab.Pane>

                                    <Tab.Pane eventKey="accountInfo">
                                        {/* <AccountInfo /> */}

                                        <h5 className="mb-3">Account Info</h5>
                                            {/* <label htmlFor="" className="inline-block mb-3 text-base font-medium">Select Business Type <span className="text-red-500">*</span></label>
                                            <div className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-4">
                                                <div>
                                                    <input id="brandingRadio" name="businessType" className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500" type="radio" value="" defaultChecked />
                                                    <label htmlFor="brandingRadio" className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500">
                                                        <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
                                                        <span className="block font-medium text-15">Branding</span>
                                                    </label>
                                                </div>

                                                <div>
                                                    <input id="mobileDesignRadio" name="businessType" className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500" type="radio" value="" />
                                                    <label htmlFor="mobileDesignRadio" className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500">
                                                        <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
                                                        <span className="block font-medium text-15">Mobile Design</span>
                                                    </label>
                                                </div>

                                                <div>
                                                    <input id="webDesignRadio" name="businessType" className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500" type="radio" value="" />
                                                    <label htmlFor="webDesignRadio" className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500">
                                                        <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
                                                        <span className="block font-medium text-15">Web Design</span>
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                                {/* <div>
                                                    <label htmlFor="categoryInput" className="inline-block mb-2 text-base font-medium">User Category <span className="text-red-500">*</span></label>
                                                    <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false 
                                                        id="categoryInput"
                                                        name="user_category_id"
                                                        onChange={validation.handleChange}
                                                        value={validation.values.user_category_id || ""}
                                                    >
                                                        <option value="">Select Category</option>
                                                        {categories?.map((category: any) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {
                                                        validation.touched.user_category_id && validation.errors.user_category_id ?
                                                            <div className="text-red-500">{validation.errors.user_category_id}</div>
                                                            : null
                                                    }
                                                </div> */}
                                                <div>
                                                    <label htmlFor="departmentInput" className="inline-block mb-2 text-base font-medium">Department <span className="text-red-500">*</span></label>
                                                    
                                                    <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false 
                                                        id="departmentInput"
                                                        name="department_id"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.department_id || ""}
                                                    >
                                                        <option value="">Select Department</option>
                                                            {departments?.map((department: any) => (
                                                            <option key={department.id} value={department.id}>
                                                                {department.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {
                                                        validation.touched.department_id && validation.errors.department_id ?
                                                            <div id="department_id" className="text-red-500">{validation.errors.department_id}</div>
                                                            : null
                                                    }
                                                </div> 

                                                <div>
                                                    <label htmlFor="phoneNumberInput" className="inline-block mb-2 text-base font-medium">Role <span className="text-red-500">*</span></label>
                                                    <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false 
                                                        id="departmentInput"
                                                        name="role_id"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.role_id || ""}
                                                    >
                                                        <option value="">Select Role</option>
                                                        {roles?.map((role: any) => (
                                                            <option key={role.id} value={role.id}>
                                                                {role.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {
                                                        validation.touched.role_id && validation.errors.role_id ?
                                                            <div className="text-red-500">{validation.errors.role_id}</div>
                                                            : null
                                                    }
                                                </div>
                                                <div>
                                                    <label htmlFor="positionInput" className="inline-block mb-2 text-base font-medium">Position</label>
                                                    <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false 
                                                        id="positionInput"
                                                        name="position_id"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.position_id || ""}
                                                    >
                                                        <option value="">Select Position</option>
                                                        {positions?.map((position: any) => (
                                                            <option key={position.id} value={position.id}>
                                                                {position.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {
                                                        validation.touched.position_id && validation.errors.position_id ?
                                                            <div className="text-red-500">{validation.errors.position_id}</div>
                                                            : null
                                                    }
                                                </div>
                                                
                                                <div>
                                                    {/* <label htmlFor="supervisorIdInput" className="inline-block mb-2 text-base font-medium">Supervisor Id <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="supervisorIdInput"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Pension Number"
                                                        name="supervisor_id"
                                                        value={validation.values.supervisor_id}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.supervisor_id && validation.errors.supervisor_id ?
                                                            <div id="supervisor_id" className="text-red-500">{validation.errors.supervisor_id}</div>
                                                            : null
                                                    } */}
                                               

                                                    <div className="xl:col-span-12">
                                                        <label
                                                        htmlFor="supervisorIdInput"
                                                        className="inline-block mb-2 text-base font-medium"
                                                        > Supervisor Id </label>

                                                        <select
                                                        className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                                                        data-choices
                                                        data-choices-search-false
                                                        id="supervisorIdInput"
                                                        name="supervisor_id"
                                                        onChange={validation.handleChange}
                                                        value={validation.values.supervisor_id || ""}
                                                        >
                                                        <option value="">Select User</option>
                                                        {users?.map((user: any) => (
                                                            <option key={user?.id} value={user?.id}>
                                                            {user?.username}
                                                            </option>
                                                        ))}
                                                        </select>
                                                        {validation.touched.supervisor_id && validation.errors.supervisor_id ? (
                                                        <p className="text-red-400">{validation.errors.supervisor_id}</p>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="taskNumberInput" className="inline-block mb-2 text-base font-medium">Lagos Tax ID <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="taskNumber"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="01234"
                                                        name="task_number"
                                                        value={validation.values.task_number}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.task_number && validation.errors.task_number ?
                                                            <div id="task_number" className="text-red-500">{validation.errors.task_number}</div>
                                                            : null
                                                    }
                                                </div>

                                                
                                                

                                                <div>
                                                    <label htmlFor="leave_daysInput" className="inline-block mb-2 text-base font-medium">Entitled Leave Days <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="leave_days"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Leave Days"
                                                        name="entitled_leave_days"
                                                        value={validation.values.entitled_leave_days}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.entitled_leave_days && validation.errors.entitled_leave_days ?
                                                            <div id="entitled_leave_days" className="text-red-500">{validation.errors.entitled_leave_days}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="leave_daysInput" className="inline-block mb-2 text-base font-medium">Leave Carried Over <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="leave_days"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Leave Days"
                                                        name="leave_carried_over"
                                                        value={validation.values.leave_carried_over}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.leave_carried_over && validation.errors.leave_carried_over ?
                                                            <div id="leave_carried_over" className="text-red-500">{validation.errors.leave_carried_over}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="leave_daysInput" className="inline-block mb-2 text-base font-medium">Leave Taken <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="leave_days"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Leave Days"
                                                        name="taken_leave_days"
                                                        value={validation.values.taken_leave_days}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.taken_leave_days && validation.errors.taken_leave_days ?
                                                            <div id="taken_leave_days" className="text-red-500">{validation.errors.taken_leave_days}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="pensionInput" className="inline-block mb-2 text-base font-medium">Pension Provider <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="pensionInput"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Pension Provider"
                                                        name="pension_provider"
                                                        value={validation.values.pension_provider}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.pension_provider && validation.errors.pension_provider ?
                                                            <div id="pension_provider" className="text-red-500">{validation.errors.pension_provider}</div>
                                                            : null
                                                    }
                                                </div>

                                                <div>
                                                    <label htmlFor="pensionNumberInput" className="inline-block mb-2 text-base font-medium">Pension Number <span className="text-red-500">*</span></label>
                                                    <input
                                                        type="text"
                                                        id="pensionNumberInput"
                                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                        placeholder="Pension Number"
                                                        name="pension_number"
                                                        value={validation.values.pension_number}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    {
                                                        validation.touched.pension_number && validation.errors.pension_number ?
                                                            <div id="pension_number" className="text-red-500">{validation.errors.pension_number}</div>
                                                            : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 mt-4 mb-4 gap-4 md:grid-cols-3">
                                                
                                                <div className="flex items-center">
                                                    <label htmlFor="isAdmin" className="inline-block text-base font-medium cursor-pointer ltr:mr-2 rtl:ml-2">Is Admin</label>
                                                    <div className="relative inline-block w-10 align-middle transition duration-200 ease-in">
                                                        <input type="checkbox"
                                                        id="isAdmin" 
                                                        name="isAdmin"
                                                        value={validation.values.isAdmin}
                                                        checked={validation.values.isAdmin}
                                                        onChange={validation.handleChange}
                                                        className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none" 
                                                        />
                                                        <label htmlFor="customDefaultSwitch" className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"></label>
                                                    </div>
                                                </div>

                                                <div className="flex items-center">
                                                    <label htmlFor="isActived" className="inline-block text-base font-medium cursor-pointer ltr:mr-2 rtl:ml-2">Activation</label>
                                                    <div className="relative inline-block w-10 align-middle transition duration-200 ease-in">
                                                        <input type="checkbox"
                                                        id="isActived" 
                                                        name="isActived"
                                                        value={validation.values.isActived}
                                                        checked={validation.values.isActived}
                                                        onChange={validation.handleChange}
                                                        className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none" 
                                                        />
                                                        <label htmlFor="customDefaultSwitch" className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"></label>
                                                    </div>
                                                </div>

                                                <div className="flex items-center">
                                                    <label htmlFor="isConfirmed" className="inline-block text-base font-medium cursor-pointer ltr:mr-2 rtl:ml-2">Confirmation</label>
                                                    <div className="relative inline-block w-10 align-middle transition duration-200 ease-in">
                                                        <input type="checkbox"
                                                        id="isConfirmed" 
                                                        name="isConfirmed"
                                                        value={validation.values.isConfirmed}
                                                        checked={validation.values.isConfirmed}
                                                        onChange={validation.handleChange}
                                                        className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none" 
                                                        />
                                                        <label htmlFor="customDefaultSwitch" className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"></label>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* <div className="flex justify-between gap-2 mt-5">
                                                <button type="button" data-action="prev" className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50"><MoveLeft className="inline-block h-4 mr-1 rtl:rotate-180" /> <span className="align-middle">Previous</span></button>
                                                <button type="submit" data-action="next" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><span className="align-middle">Next</span> <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" /></button>
                                            </div> */}
                                        

                                    </Tab.Pane>

                                    <Tab.Pane eventKey="documentUploadInfo">
                                        {/* <AddressInfo /> */}

                                        <h5 className="mb-3">Security</h5>

                                        <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                            <div>
                                                <label htmlFor="corporateEmailInput" className="inline-block mb-2 text-base font-medium">Corporate Email <span className="text-red-500">*</span></label>
                                                <input
                                                    type="email"
                                                    id="corporateEmailInput"
                                                    name="corporate_email"
                                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                    placeholder="Corporate Email"
                                                    value={validation.values.corporate_email}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                />
                                                {
                                                    validation.touched.corporate_email && validation.errors.corporate_email ?
                                                        <div className="text-red-500">{validation.errors.corporate_email}</div>
                                                        : null
                                                }
                                            </div>

                                            <div>
                                                <label htmlFor="employeeIdInput" className="inline-block mb-2 text-base font-medium">Employee ID <span className="text-red-500">*</span></label>
                                                <input
                                                    type="text"
                                                    id="employeeIdInput"
                                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                    placeholder="000000"
                                                    name="employee_id"
                                                    value={validation.values.employee_id}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                />
                                                {
                                                    validation.touched.employee_id && validation.errors.employee_id ?
                                                        <div id="employee_id" className="text-red-500">{validation.errors.employee_id}</div>
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                                            <div>
                                                <label htmlFor="passwordInput" className="inline-block mb-2 text-base font-medium">Password <span className="text-red-500">*</span></label>
                                                <input
                                                    type="text"
                                                    id="passwordInput"
                                                    name="password"
                                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                    placeholder="********"
                                                    value={validation.values.password}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                />
                                                {
                                                    validation.touched.password && validation.errors.password ? <div className="text-red-500">{validation.errors.password}</div> : null
                                                }
                                            </div>
                                            <div>
                                                <label htmlFor="confirmPasswordInput" className="inline-block mb-2 text-base font-medium">Confirm Password <span className="text-red-500">*</span></label>
                                                <input
                                                    type="text"
                                                    id="confirmPasswordInput"
                                                    name="confirm_password"
                                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                    placeholder="********"
                                                    value={validation.values.confirm_password}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                />
                                                {
                                                    validation.touched.confirm_password && validation.errors.confirm_password ? <div className="text-red-500">{validation.errors.confirm_password}</div> : null
                                                }
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 mt-4 mb-4 gap-4 md:grid-cols-2">
                                            <div>
                                                <div className="flex items-center">
                                                    <label htmlFor="twoFactor" className="inline-block text-base font-medium cursor-pointer ltr:mr-2 rtl:ml-2">Activate Two Factor Auth</label>
                                                    <div className="relative inline-block w-10 align-middle transition duration-200 ease-in">
                                                        <input type="checkbox"
                                                        id="twoFactor" 
                                                        name="two_factor"
                                                        value={validation.values.two_factor}
                                                        checked={validation.values.two_factor}
                                                        onChange={validation.handleChange}
                                                        className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none" 
                                                        />
                                                        <label htmlFor="customDefaultSwitch" className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div>
                                                <div className="flex items-center">
                                                    <label htmlFor="verify_email" className="inline-block text-base font-medium cursor-pointer ltr:mr-2 rtl:ml-2">Verify Email</label>
                                                    <div className="relative inline-block w-10 align-middle transition duration-200 ease-in">
                                                        <input type="checkbox"
                                                        id="verify_email" 
                                                        name="verify_email"
                                                        value={validation.values.verify_email}
                                                        checked={validation.values.verify_email}
                                                        onChange={validation.handleChange}
                                                        className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none" 
                                                        />
                                                        <label htmlFor="customDefaultSwitch" className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <form action="#!" onSubmit={(event: any) => {
                                            event.preventDefault();
                                            validation.handleSubmit()
                                        }}>

                                            <div className="card">
                                                <div className="card-body">
                                                    <h6 className="mb-4 text-15">Dropzone</h6>
                                                    <div className="flex items-center justify-center border rounded-md cursor-pointer bg-slate-100 dropzone border-slate-200 dark:bg-zink-600 dark:border-zink-500 dz-clickable">

                                                        <Dropzone
                                                            onDrop={(acceptedFiles: any) => {
                                                                handleAcceptedFiles(acceptedFiles)
                                                            }}
                                                        >
                                                            {({ getRootProps, getInputProps }: any) => (
                                                                <div
                                                                    className="w-full py-5 text-lg text-center dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <div className="mb-3">
                                                                        <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                                                                    </div>

                                                                    <h5 className="mb-0 font-normal text-slate-500 text-15">Drag and drop your files or <a href="#!">browse</a> your files</h5>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>

                                                    <ul className="mb-0" id="dropzone-preview">
                                                        {
                                                            (selectedFiles || [])?.map((f: any, i: any) => {
                                                                return (
                                                                    <li className="mt-2" id="dropzone-preview-list" key={i + "-file"}>
                                                                        <div className="border rounded border-slate-200 dark:border-zink-500">
                                                                            <div className="flex p-2">
                                                                                <div className="shrink-0 me-3">
                                                                                    <div className="p-2 rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                                                                                        <img data-dz-thumbnail className="block w-full h-full rounded-md" src={f.preview} alt={f.name} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="grow">
                                                                                    <div className="pt-1">
                                                                                        <h5 className="mb-1 text-15" data-dz-name>{f.name}</h5>
                                                                                        <p className="mb-0 text-slate-500 dark:text-zink-200" data-dz-size>{f.formattedSize}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="shrink-0 ms-3">
                                                                                    <button data-dz-remove
                                                                                        className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                                                                        onClick={() => {
                                                                                            const newImages = [...selectedFiles];
                                                                                            newImages.splice(i, 1);
                                                                                            setSelectedFiles(newImages);
                                                                                        }}
                                                                                    >Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                        </form> */}

                                    </Tab.Pane>

                                    <Tab.Pane eventKey="completed">
                                        {/* <Completed /> */}
                                        
                                        <h5 className="mb-3">Confirmation</h5>

                                        {validation.errors.firstname || validation.errors.lastname || validation.errors.mobile_numbers || validation.errors.dob || validation.errors.gender || validation.errors.contact_address || validation.errors.personal_email || validation.errors.employment_date ||validation.errors.employee_id || validation.errors.supervisor_id || validation.errors.position_id || validation.errors.role_id ? (

                                            <Alert className="px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-400/20">
                                                <Alert.Bold>Warning:</Alert.Bold> Kindly step back and fill the required inputs
                                                <ul className=" mt-2 space-y-1 list-disc list-inside rounded-md">
                                                    
                                                    {validation.errors.firstname ? (
                                                        <li className="error">{validation.errors.firstname}</li>
                                                    ) : null}

                                                    {validation.errors.lastname ? (
                                                        <li className="error">{validation.errors.lastname}</li>
                                                    ) : null}
                                                    {validation.errors.mobile_numbers ? (
                                                        <li className="error">{validation.errors.mobile_numbers}</li>
                                                    ) : null}

                                                    { validation.errors.dob ? (
                                                        <li className="error">{validation.errors.dob}</li>
                                                    ) : null}

                                                    {validation.errors.gender ? (
                                                        <li className="error">{validation.errors.gender}</li>
                                                    ) : null}

                                                    {validation.errors.contact_address ? (
                                                        <li className="error">{validation.errors.contact_address}</li>
                                                    ) : null}

                                                    {validation.errors.personal_email ? (
                                                        <li className="error">{validation.errors.personal_email}</li>
                                                    ) : null}

                                                    {validation.errors.employment_date ? (
                                                        <li className="error">{validation.errors.employment_date}</li>
                                                    ) : null}

                                                    {validation.errors.employee_id ? (
                                                        <li className="error">{validation.errors.employee_id}</li>
                                                    ) : null}

                                                    {validation.errors.supervisor_id ? (
                                                        <li className="error">{validation.errors.supervisor_id}</li>
                                                    ) : null}

                                                    {validation.errors.position_id ? (
                                                        <li className="error">{validation.errors.position_id}</li>
                                                    ) : null}

                                                    {validation.errors.role_id ? (
                                                        <li className="error">{validation.errors.role_id}</li>
                                                    ) : null}
                                                    
                                                </ul>

                                            </Alert>

                                        ) : null}

                                        

                                        { submitedForm ? 

                                            <div className="px-4 py-6 mx-auto text-center lg:w-2/3">
                                                <CheckCircle className="block size-10 mx-auto mb-4 text-green-500 fill-green-100 dark:fill-green-500/20 animate-icons"></CheckCircle>
                                                <h5 className="mb-2">Registration Successfully 🎉</h5>
                                                <p className="mb-5 text-slate-500 text-15">We are thrilled to inform you that your registration has been successfully processed! Welcome to our community. Thank you for choosing us, and we look forward to serving you. Stay connected and enjoy your journey with us!</p>
                                                <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                                    onClick={() => navigate("/apps-users-list")}
                                                ><span className="align-middle">Go to All Users</span> <LogIn className="inline-block h-4 ltr:ml-1 rtl:mr-1"></LogIn></button>
                                            </div> :

                                            <div className="px-4 py-6 mx-auto lg:w-2/3">
                                                <LucideMessageCircleWarning className="block size-10 mx-auto mb-4 text-yellow-500 fill-yellow-100 dark:fill-yellow-500/20 animate-icons"></LucideMessageCircleWarning>
                                                <h5 className="mb-2">Are you sure you want to submit user onboarding information?</h5>
                                                
                                                <ul className="space-y-3 list-disc list-inside rounded-md">
                                                    <li><b>Ensure All Fields are Complete:</b> Double-check that all required fields are filled out accurately.t</li>
                                                    <li><b>eview Your Information:</b>  Confirm that your email address and other personal details are correct, as this will be used for account verification.</li>
                                                    <li><b>Understand Our Terms:</b> By submitting, you agree to our [Terms of Service] and [Privacy Policy]. Make sure you’ve read and understood them.</li>
                                                    <li><b>Support:</b> If you have any questions or need assistance, don’t hesitate to reach out to our support team at [Support Email/Link].</li>
                                                </ul>

                                                <div className="flex justify-end"> {/* This will align the button to the left */}

                                                    { !loadingForm ?

                                                        <button
                                                        type="submit"
                                                        className="mt-4 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"   
                                                        >
                                                        <span className="align-middle">Submit</span>
                                                        <LogIn className="inline-block h-4 ltr:ml-1 rtl:mr-1" />
                                                        </button>
                                                        :
                                                        <button type="button" className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                                        <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                                                        Processing...
                                                        </button>
                                                    
                                                    }
                                                </div>
                                            </div>

                                        }
                                        
                                    </Tab.Pane>
                                </Tab.Content>

                            </form>

                        </Tab.Container>

                    </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

export default AccountInfo;