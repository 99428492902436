import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CreatableSelect from 'react-select/creatable';

// Icons
import { Search, Plus, Trash2, Pencil, MoreHorizontal, FileEdit, Eye, InfoIcon } from "lucide-react";
import TableContainer from "Common/TableContainer";

import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getAppraisals as onGetAppraisals,
  getActiveAppraisalCategories as onGetActiveAppraisalCategories,
  createAppraisal as onCreateAppraisal,
  updateAppraisal as onUpdateAppraisal,
  deleteAppraisal as onDeleteAppraisal,
} from "slices/thunk";
// import { getDepartments as onGetDepartments } from "services/api_service";
import { ToastContainer } from "react-toastify";
import Pagination from "Common/Pagination";
import { Dropdown } from "Common/Components/Dropdown";

interface Option {
  readonly label: string;
  readonly value?: string;
  readonly options?: Option[];
  readonly isDisabled?: boolean
}

const AppraisalAdmin = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.appraisals,
      dataDetails: state.appraisalDetails,
      activeCategories: state.acativeAppraisalCategories,
    })
  );

  const { dataList, activeCategories} = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [categories, setCategories] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Get Data
  useEffect(() => {
    dispatch(onGetAppraisals({ page: 1, per_page: 20 }));
    dispatch(onGetActiveAppraisalCategories());
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setCategories(activeCategories?.data);
  }, [dataList, activeCategories]);

  
  // largeModal
  const [largeModal, setLargeModal] = useState<boolean>(false);
  const largeToggle = () => setLargeModal(!largeModal);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteAppraisal(eventData.id));
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category_id: (eventData && eventData.name) || "",
      goals: (eventData && eventData.item) || "",
      achivements: (eventData && eventData.item) || "",
      challenges: (eventData && eventData.description) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      category_id: Yup.string().required("Please Select Your Appraisal Category"),
      goals: Yup.string().required("Please state the goals for the period"),
      achivements: Yup.string().required("Please state the goals achieved for the period"),
      challenges: Yup.string().required("Please state the challengies"),
      status: Yup.string()
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateAppraisal(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onCreateAppraisal(newData));
      }
       toggle();
    },
  });

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Status
  const Status = ({ item }: any) => {
    switch (item) {
        case "active":
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
        case "pending":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:text-zink-200 dark:border-transparent status">{item}</span>);
        case "inactive":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">{item}</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
    }
  };

  const Type = ({ item }: any) => {
    switch (item) {
        case "half_yr":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Half Year</span>);
        case "full_yr":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Full Year</span>);
        case "confirmation":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Confirmations</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">{item}</span>);
    }
  };

  const columns = useMemo(() => [
    {
        header: (
            <div className="flex items-center h-full">
                <input id="CheckboxAll" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
            </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
            return (
                <div className="flex items-center h-full">
                    <input id="Checkbox1" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
                </div>
            );
        }
    },
    {
        header: "Appraisal ID",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link to={`/appaisals/${cell.row.original.id}`} className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"><Type item={cell.getValue()} /></Link>
        ),
    },
    {
        header: "Employee",
        accessorKey: "employee_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
            <div className="flex items-center gap-2">
                <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
                    {cell.row.original.employee_image ? <img src={cell.row.original.employee_image} alt="" className="h-10 rounded-full" /> : ''}
                </div>
                <div className="grow">
                    <h6 className="mb-1"><Link to="#!" className="name">{cell.getValue()}</Link></h6>
                    <p className="text-slate-500 dark:text-zink-200">{cell.row.original.employee_role}</p>
                </div>
            </div>
        ),
    },
    {
      header: "Type",
      accessorKey: "appraisal_category.type",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <Type item={cell.getValue()} />
      ),
    },
    {
        header: "Period",
        accessorKey: "appraisal_category.year",
        enableColumnFilter: false,
        cell: (cell: any) => (
            <div className="flex items-center gap-2">
                <div className="grow">
                    <h6 className="mb-1"><Link to="#!" className="name">{cell.row.original.appraisal_category.month}</Link></h6>
                    <p className="text-slate-500 dark:text-zink-200">{cell.getValue()}</p>
                </div>
            </div>
        ),
    },
    {
        header: "Supervisor",
        accessorKey: "supervisor_name",
        enableColumnFilter: false,
    },
    {
      header: "Scores",
      accessorKey: "total_scores",
      enableColumnFilter: false,
    },
    {
      header: "created At",
      accessorKey: "created_at",
      enableColumnFilter: false,
    },
    {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
            <Status item={cell.getValue()} />
        ),
    },
    {
      header: "Action",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
          <Dropdown className="relative">
              <Dropdown.Trigger className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20" id="usersAction1">
                  <MoreHorizontal className="size-3" />
              </Dropdown.Trigger>
              <Dropdown.Content placement="right-end" className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600" aria-labelledby="usersAction1">
                  <li>
                      <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to={`/user-account/${cell.row.original.id}`}><Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Overview</span></Link>
                  </li>
                  <li>
                      <Link data-modal-target="addUserModal" className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!"
                          onClick={() => {
                              const data = cell.row.original;
                              handleUpdateDataClick(data);
                          }}>
                          <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Edit</span></Link>
                  </li>
                  <li>
                      <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!" onClick={() => {
                          const orderData = cell.row.original;
                          onClickDelete(orderData);
                      }}><Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Delete</span></Link>
                  </li>
              </Dropdown.Content>
          </Dropdown>
      ),
    }
  ], []);

  // Pagination
  const [currentPage, setCurrentPage] = useState<any>(1);

  const perPageData = 20;
  useEffect(() => {
    console.log('currentPage', currentPage);
    setCurrentPage(currentPage);
      // setData(currentdata);
      // handlePagination(currentPage);
      dispatch(onGetAppraisals({ page: currentPage, per_page: perPageData }));
      
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <BreadCrumb title="Appraisal List" pageTitle="Appraisals" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">Appraisals</h6>
            {/* <div className="shrink-0">

              <Link
                to="#!"
                data-modal-target="largeModal"
                type="button"
                className="text-white mr-2 btn bg-yellow-500 border-yellow-500 hover:text-white hover:bg-yellow-600 hover:border-yellow-600 focus:text-white focus:bg-yellow-600 focus:border-yellow-600 focus:ring focus:ring-yellow-100 active:text-white active:bg-yellow-600 active:border-yellow-600 active:ring active:ring-yellow-100 dark:ring-yellow-400/20"
                onClick={largeToggle}
              >
                <InfoIcon className="inline-block size-4" />
                <span className="align-middle">Introduction</span>
              </Link>

              <Link
                to="#!"
                data-modal-target="addAppraisalModal"
                type="button"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                onClick={toggle}
              >
                <Plus className="inline-block size-4" />
                <span className="align-middle">Open New Appraisal</span>
              </Link>
            </div> */}
          </div>
          {data && data.length > 0 ? (
            <TableContainer
              isPagination={false}
              columns={columns || []}
              data={data || []}
              customPageSize={perPageData}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right"
              thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  We've searched more than 5+ times We did not find any
                  appraisal category data for you search.
                </p>
              </div>
            </div>
          )}

        <Pagination
          perPageData={perPageData}
          data={dataList ? dataList?.data : dataList}
          total={dataList ? dataList?.meta?.total : 0}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentdata={dataList? dataList?.data : []}
        />
        </div>
      </div>

      {/* Department Modal */}

      <Modal show={largeModal} onHide={largeToggle} id="largeModal" modal-center="true"
          className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
          dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full">
          <Modal.Header className="flex items-center justify-between p-4 border border-custom-500 dark:border-custom-800"
              closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500">
              <Modal.Title className="text-16">Appraisal & Confirmation Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
              <h5 className="mb-3 text-16"><u>Summary</u></h5>

              <h3 className="mb-3 text-16"><u>The purpose of the confirmation review:</u></h3>

              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">Review the progress and performance demonstrated to date.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">Compare actual performance with agreed goals for the probation period.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">
                  To serve as a basis for modifying or changing work patterns
                  and behaviour towards more effective working habits.
                </span>
              </li>

              <h3 className="mb-2 text-16 mt-3"><u>Instructions and Guidelines:</u></h3>

              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200"> The appraisee initiates the confirmation appraisal process
                by completing this appraisal form.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">Discussion between the appraisee and the supervisor is
                mandatory prior to sign off on the form.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">
                All forms must be filled and completed within five (5) days
                of commencement of the appraisals.
                </span>
              </li>

              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">
                Supervisors are expected to review employee performance
                  based on the goals agreed.
                </span>
              </li>

              <h6 className="mb-4 mt-4 text-15">Overall Ranking</h6>
              <div className="overflow-x-auto">
                <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                    <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                        <tr>
                            <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">GRADE</th>
                            <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">RANKING</th>
                            <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">WEIGHTED SCORE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">5</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Outstanding</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 80% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">4</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Good</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 70%  - 79% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">3</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Satisfactory</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 60%  - 69% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">2</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Weak</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 500%  - 59% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">1</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Very Weak</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved below 49% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">0</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">NA</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Not Applicable to the staff</td>
                        </tr>
                    </tbody>
                </table>
              </div>

          </Modal.Body>
          <Modal.Footer className="flex items-center justify-between p-4 mt-auto border-t border-slate-200 dark:border-zink-500">
              <h5 className="text-16">Best of Luck</h5>
          </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Appraisal" : "Create New Appraisal"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">

              <div className="xl:col-span-12">
                <label htmlFor="CategoryInput" className="inline-block mb-2 text-base font-medium">Appraisal Category</label>
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="CategoryInput"
                  name="category_id"
                  onChange={validation.handleChange}
                  value={validation.values.category_id || ""}
                >
                  <option value="">Select Appraisal Category</option>
                  {categories?.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.month}, {category.year} - {category.type == 'half_yr' ? 'Half Year' : category.type == 'full_yr' ? 'Full Year' : 'Confirmation'}
                    </option>
                  ))}
                </select>
                {validation.touched.category_id && validation.errors.category_id ? (
                    <p className="text-red-400">{validation.errors.category_id}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label htmlFor="goalInput" className="inline-block mb-2 text-base font-medium">Goals to Achieve</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="goalInput" name="goals" placeholder="State the goals for the period"
                  value={validation.values.goals}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.goals && validation.errors.goals ? <div id="goals" className="text-red-500">{validation.errors.goals}</div> : null }
              </div>
             
              <div className="xl:col-span-12">
                <label htmlFor="goalAchievedInput" className="inline-block mb-2 text-base font-medium">Goals Achieved</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="goalAchievedInput" name="achivements" placeholder="State the goals achieved for the period"
                  value={validation.values.achivements}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.achivements && validation.errors.achivements ? <div id="achivements" className="text-red-500">{validation.errors.achivements}</div> : null }
              </div>

              <div className="xl:col-span-12">
                <label htmlFor="goalChallengesInput" className="inline-block mb-2 text-base font-medium">Challenges</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="goalChallengesInput" name="challenges" placeholder="State the challengies"
                  value={validation.values.challenges}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.challenges && validation.errors.challenges ? <div id="challenges" className="text-red-500">{validation.errors.challenges}</div> : null }
              </div>

              {isEdit && (
                <div className="xl:col-span-12">
                    <label htmlFor="statusInput" className="inline-block mb-2 text-base font-medium">Status</label>
                    <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                        id="statusInput"
                        name="status"
                        onChange={validation.handleChange}
                        value={validation.values.status || ""}
                    >
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    {validation.touched.status && validation.errors.status ? (
                        <p className="text-red-400">{validation.errors.status}</p>
                    ) : null}
                </div>
              )}

            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addAppraisalModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Submit Apraisal"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AppraisalAdmin;


