import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CreatableSelect from 'react-select/creatable';
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";

// Icons
import { Search, Plus, Trash2, Pencil, MoreHorizontal, FileEdit, Eye, InfoIcon } from "lucide-react";
import TableContainer from "Common/TableContainer";

import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  // getAppraisals as onGetAppraisals,
  getUserAppraisals as onGetUserAppraisals,
  createAppraisal as onCreateAppraisal,
  updateAppraisal as onUpdateAppraisal,
  deleteAppraisal as onDeleteAppraisal,
  getCoulleaguePerformance as onGetCoulleaguePerformance,
  getActiveAppraisalCategories as onGetActiveAppraisalCategories,
  checkOpenedAppraisalCategories as onCheckOpenedAppraisalCategories
} from "slices/thunk";
// import { getDepartments as onGetDepartments } from "services/api_service";
import { ToastContainer } from "react-toastify";
import Pagination from "Common/Pagination";
import { Dropdown } from "Common/Components/Dropdown";

interface Option {
  readonly label: string;
  readonly value?: string;
  readonly options?: Option[];
  readonly isDisabled?: boolean
}

const Appraisals = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.userAppraisals,
      dataDetails: state.appraisalDetails,
      checkAppraisal: state.checkAppraisalCategories,
      activeCategories: state.acativeAppraisalCategories,
      getCoulleaguePerformance: state.coulleaguePerformance,
    })
  );

  const { dataList, activeCategories, checkAppraisal, getCoulleaguePerformance} = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [performances, setPerformances] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [appraisal, setAppraisal] = useState<any>({});
  const [categories, setCategories] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Get Data
  useEffect(() => {
    dispatch(onGetCoulleaguePerformance());
    dispatch(onGetActiveAppraisalCategories());
    dispatch(onCheckOpenedAppraisalCategories());
    dispatch(onGetUserAppraisals({ page: 1, per_page: 20 }));
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setAppraisal(checkAppraisal?.data);
    setCategories(activeCategories?.data);
    setPerformances(getCoulleaguePerformance?.data);
  }, [dataList, activeCategories, checkAppraisal, getCoulleaguePerformance]);

  
  // largeModal
  const [largeModal, setLargeModal] = useState<boolean>(false);
  const largeToggle = () => setLargeModal(!largeModal);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteAppraisal(eventData.id));
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category_id: (eventData && eventData.appraisal_category_id) || "",
      goals: (eventData && eventData.goals) || "",
      achivements: (eventData && eventData.achivements) || "",
      challenges: (eventData && eventData.challenges) || "",
      strength: (eventData && eventData.strength) || "",
      improvements: (eventData && eventData.improvements) || "",
      recommendations: (eventData && eventData.recommendations) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      category_id: Yup.string().required("Please Select Your Appraisal Category"),
      goals: Yup.string().required("Please state the goals for the period"),
      achivements: Yup.string().required("Please state the goals achieved for the period"),
      challenges: Yup.string(),
      strength: Yup.string(),
      improvements: Yup.string(),
      recommendations: Yup.string(),
      status: Yup.string()
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateAppraisal(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onCreateAppraisal(newData));
      }
       toggle();
    },
  });

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Status
  const Status = ({ item }: any) => {
    switch (item) {
        case "opened":
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-blue-100 border-transparent text-blue-500 dark:bg-blue-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
        case "pending":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:text-zink-200 dark:border-transparent status">{item}</span>);
        case "closed":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent status">{item}</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
    }
  };

  const Type = ({ item }: any) => {
    switch (item) {
        case "half_yr":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Half Year</span>);
        case "full_yr":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Full Year</span>);
        case "confirmation":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Confirmations</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">{item}</span>);
    }
  };

  const columns = useMemo(() => [
    {
        header: (
            <div className="flex items-center h-full">
                <input id="CheckboxAll" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
            </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
            return (
                <div className="flex items-center h-full">
                    <input id="Checkbox1" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
                </div>
            );
        }
    },
    {
      header: "Appraisal ID",
      accessorKey: "ref_no",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <Link to={`/appaisals/${cell.row.original.id}`} className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"><Type item={cell.getValue()} /></Link>
      ),
    },
    {
      header: "Start Date",
      accessorKey: "created_at",
      enableColumnFilter: false,
    },

    // {
    //     header: "Employee",
    //     accessorKey: "employee_name",
    //     enableColumnFilter: false,
    //     cell: (cell: any) => (
    //         <div className="flex items-center gap-2">
    //             <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
    //                 {cell.row.original.employee_image ? <img src={cell.row.original.employee_image} alt="" className="h-10 rounded-full" /> : ''}
    //             </div>
    //             <div className="grow">
    //                 <h6 className="mb-1"><Link to="#!" className="name">{cell.getValue()}</Link></h6>
    //                 <p className="text-slate-500 dark:text-zink-200">{cell.row.original.employee_role}</p>
    //             </div>
    //         </div>
    //     ),
    // },
    {
      header: "Type",
      accessorKey: "appraisal_category.type",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <Type item={cell.getValue()} />
      ),
    },
    {
        header: "Period",
        accessorKey: "appraisal_category.year",
        enableColumnFilter: false,
        cell: (cell: any) => (
            <div className="flex items-center gap-2">
                <div className="grow">
                    <h6 className="mb-1"><Link to="#!" className="name">{cell.row.original.appraisal_category.month}</Link></h6>
                    <p className="text-slate-500 dark:text-zink-200">{cell.getValue()}</p>
                </div>
            </div>
        ),
    },
    {
        header: "Supervisor",
        accessorKey: "supervisor_name",
        enableColumnFilter: false,
    },
    {
      header: "Scores",
      accessorKey: "total_scores",
      enableColumnFilter: false,
    },
    {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
            <Status item={cell.getValue()} />
        ),
    },

    {
      header: "Action",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
          <div className="flex gap-3">
              <Link className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500" to={`/appaisals/${cell.row.original.id}`}><Eye className="inline-block size-3" /> </Link>
              <Link to="#!" data-modal-target="addEmployeeModal" className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md edit-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-yellow-600 dark:text-yellow-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500" onClick={() => {
                  const data = cell.row.original;
                  handleUpdateDataClick(data);
              }}>
                  <Pencil className="size-4" /></Link>
              <Link to="#!" className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md remove-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-red-600 dark:text-red-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500" onClick={() => {
                  const data = cell.row.original;
                  onClickDelete(data);
              }}><Trash2 className="size-4" /></Link>
          </div>
      ),
    }
  ], []);

  // Pagination
  const [currentPage, setCurrentPage] = useState<any>(1);

  const perPageData = 20;
  useEffect(() => {
    console.log('currentPage', currentPage);
    setCurrentPage(currentPage);
      // setData(currentdata);
      // handlePagination(currentPage);
      dispatch(onGetUserAppraisals({ page: currentPage, per_page: perPageData }));
      
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <BreadCrumb title="Appraisal List" pageTitle="Appraisals" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />

      <div className="grid grid-cols-2 2xl:grid-cols-4 gap-x-5">
          <div>
              <div className="card">
                  <div className="text-center card-body">
                      <h6 className="mb-1 underline">{dataList?.meta?.total}</h6>
                      <p className="uppercase text-slate-500 dark:text-zink-200">Total Appraisal</p>
                  </div>
              </div>
          </div>
          <div>
              <div className="card">
                  <div className="text-center card-body">
                      <h6 className="mb-1">0</h6>
                      <p className="uppercase text-slate-500 dark:text-zink-200">Pending To Appraised</p>
                  </div>
              </div>
          </div>
          <div>
              <div className="card">
                  <div className="text-center card-body">
                    {
                      appraisal?.status ? (
                        <h6 className="mb-1">
                          {appraisal?.category?.type == 'half_yr' ? `Half Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal` : appraisal?.category?.type == 'full_yr' ? `Full Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal` : `Confirmation - ${appraisal?.category?.month}, ${appraisal?.category?.year}`}</h6>
                      ) :(
                        <h6 className="mb-1">N/A</h6>
                      )
                    }
                      <p className="uppercase text-slate-500 dark:text-zink-200">Ongoing Type</p>
                  </div>
              </div>
          </div>
          {/* <div>
              <div className="card">
                  <div className="text-center card-body">
                      <h6 className="mb-1">$843.49</h6>
                      <p className="uppercase text-slate-500 dark:text-zink-200">Order Amount</p>
                  </div>
              </div>
          </div> */}

          <div>
              <div className="card">
                  <div className="text-center card-body">
                    {
                      appraisal?.status ? (
                        <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">Opened</span>
                      ) :(
                        <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20">Closed</span>
                      )
                    }
                      <p className="uppercase text-slate-500 dark:text-zink-200">Ongoing Status</p>
                  </div>
              </div>
          </div>
      </div>

      <div className="card" id="ordersTable">
        <div className="card-body">
          <div>
            <Tab.Container defaultActiveKey="homeJustified">
                <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
                    <Nav.Item eventKey="homeJustified" className="group grow">
                        <a href="#!" data-tab-toggle data-target="homeJustified" className="inline-block px-4 py-2 text-base w-full transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 dark:group-[.active]:hover:text-white -mb-[1px]">
                          My Appraisals
                        </a>
                    </Nav.Item>
                    <Nav.Item eventKey="ProfileJustified" className="group grow">
                        <a href="#!" data-tab-toggle data-target="ProfileJustified" className="inline-block px-4 py-2 text-base w-full transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 dark:group-[.active]:hover:text-white -mb-[1px]">
                          Colleagues Appraisals
                        </a>
                    </Nav.Item>
                    <Nav.Item eventKey="settingJustified" className="group grow">
                        <a href="#!" data-tab-toggle data-target="settingJustified" className="inline-block px-4 py-2 text-base w-full transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 dark:group-[.active]:hover:text-white -mb-[1px]">
                          Supervisor Appraisals
                        </a>
                    </Nav.Item>
                    {/* <Nav.Item eventKey="contactJustified" className="group grow">
                        <a href="#!" data-tab-toggle data-target="contactJustified" className="inline-block px-4 py-2 text-base w-full transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 dark:group-[.active]:hover:text-white -mb-[1px]">
                          Contacts
                        </a>
                    </Nav.Item> */}
                </Nav>

                <Tab.Content className="mt-5 tab-content">
                    <Tab.Pane eventKey="homeJustified" id="homeJustified">
                      <div className="flex items-center gap-3 mb-4">
                        <h6 className="text-15 grow">Appraisals</h6>
                        <div className="shrink-0">

                          <Link
                            to="#!"
                            data-modal-target="largeModal"
                            type="button"
                            className="text-white mr-2 btn bg-yellow-500 border-yellow-500 hover:text-white hover:bg-yellow-600 hover:border-yellow-600 focus:text-white focus:bg-yellow-600 focus:border-yellow-600 focus:ring focus:ring-yellow-100 active:text-white active:bg-yellow-600 active:border-yellow-600 active:ring active:ring-yellow-100 dark:ring-yellow-400/20"
                            onClick={largeToggle}
                          >
                            <InfoIcon className="inline-block size-4" />
                            <span className="align-middle">Introduction</span>
                          </Link>

                          <Link
                            to="#!"
                            data-modal-target="addAppraisalModal"
                            type="button"
                            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                            onClick={toggle}
                          >
                            <Plus className="inline-block size-4" />
                            <span className="align-middle">Start New Appraisal</span>
                          </Link>
                        </div>
                      </div>
                      {data && data.length > 0 ? (
                        <TableContainer
                          isPagination={false}
                          columns={columns || []}
                          data={data || []}
                          customPageSize={perPageData}
                          divclassName="overflow-x-auto"
                          tableclassName="w-full whitespace-nowrap"
                          theadclassName="ltr:text-left rtl:text-right"
                          thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
                          tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
                          PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                        />
                      ) : (
                        <div className="noresult">
                          <div className="py-6 text-center">
                            <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                            <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                            <p className="mb-0 text-slate-500 dark:text-zink-200">
                              We've searched more than 5+ times We did not find any
                              appraisal category data for you search.
                            </p>
                          </div>
                        </div>
                      )}

                      <Pagination
                        perPageData={perPageData}
                        data={dataList ? dataList?.data : dataList}
                        total={dataList ? dataList?.meta?.total : 0}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        currentdata={dataList? dataList?.data : []}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="ProfileJustified" id="ProfileJustified">
                        <p className="mb-0">.... </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="settingJustified" id="settingJustified">
                        <p className="mb-0">....</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="contactJustified" id="contactJustified">
                        <p className="mb-0">.... </p>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>

      {/* Department Modal */}

      <Modal show={largeModal} onHide={largeToggle} id="largeModal" modal-center="true"
          className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
          dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full">
          <Modal.Header className="flex items-center justify-between p-4 border border-custom-500 dark:border-custom-800"
              closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500">
              <Modal.Title className="text-16">Appraisal & Confirmation Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
              <h5 className="mb-3 text-16"><u>Summary</u></h5>

              <h3 className="mb-3 text-16"><u>The purpose of the confirmation review:</u></h3>

              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">Review the progress and performance demonstrated to date.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">Compare actual performance with agreed goals for the probation period.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">
                  To serve as a basis for modifying or changing work patterns
                  and behaviour towards more effective working habits.
                </span>
              </li>

              <h3 className="mb-2 text-16 mt-3"><u>Instructions and Guidelines:</u></h3>

              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200"> The appraisee initiates the confirmation appraisal process
                by completing this appraisal form.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">Discussion between the appraisee and the supervisor is
                mandatory prior to sign off on the form.</span>
              </li> 
              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">
                All forms must be filled and completed within five (5) days
                of commencement of the appraisals.
                </span>
              </li>

              <li data-v-05645a84>
                <span className="text-slate-500 dark:text-zink-200">
                Supervisors are expected to review employee performance
                  based on the goals agreed.
                </span>
              </li>

              <h6 className="mb-4 mt-4 text-15">Overall Ranking</h6>
              <div className="overflow-x-auto">
                <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                    <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                        <tr>
                            <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">GRADE</th>
                            <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">RANKING</th>
                            <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">WEIGHTED SCORE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">5</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Outstanding</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 80% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">4</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Good</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 70%  - 79% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">3</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Satisfactory</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 60%  - 69% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">2</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Weak</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved at least 500%  - 59% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">1</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Very Weak</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Achieved below 49% of all set target</td>
                        </tr>
                        <tr>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800"><a href="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">0</a></td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">NA</td>
                            <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">Not Applicable to the staff</td>
                        </tr>
                    </tbody>
                </table>
              </div>

          </Modal.Body>
          <Modal.Footer className="flex items-center justify-between p-4 mt-auto border-t border-slate-200 dark:border-zink-500">
              <h5 className="text-16">Best of Luck</h5>
          </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full">
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Appraisal" : "Create New Appraisal"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">

              <div className="xl:col-span-12">
                <label htmlFor="CategoryInput" className="inline-block mb-2 text-base font-medium">Appraisal Category</label>
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="CategoryInput"
                  name="category_id"
                  onChange={validation.handleChange}
                  value={validation.values.category_id || ""}
                >
                  <option value="">Select Appraisal Category</option>
                  {categories?.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.month}, {category.year} - {category.type == 'half_yr' ? 'Half Year' : category.type == 'full_yr' ? 'Full Year' : 'Confirmation'}
                    </option>
                  ))}
                </select>
                {validation.touched.category_id && validation.errors.category_id ? (
                    <p className="text-red-400">{validation.errors.category_id}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label htmlFor="goalInput" className="inline-block mb-2 text-base font-medium">Goals to Achieve</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="goalInput" name="goals" placeholder="State the goals for the period"
                  value={validation.values.goals}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={5}>
                </textarea>
                {validation.touched.goals && validation.errors.goals ? <div id="goals" className="text-red-500">{validation.errors.goals}</div> : null }
              </div>
             
              <div className="xl:col-span-12">
                <label htmlFor="goalAchievedInput" className="inline-block mb-2 text-base font-medium">Goals Achieved</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="goalAchievedInput" name="achivements" placeholder="State the goals achieved for the period"
                  value={validation.values.achivements}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={5}>
                </textarea>
                {validation.touched.achivements && validation.errors.achivements ? <div id="achivements" className="text-red-500">{validation.errors.achivements}</div> : null }
              </div>

              <div className="xl:col-span-12">
                <label htmlFor="goalChallengesInput" className="inline-block mb-2 text-base font-medium">Challenges</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="goalChallengesInput" name="challenges" placeholder="State the challengies"
                  value={validation.values.challenges}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={5}>
                </textarea>
                {validation.touched.challenges && validation.errors.challenges ? <div id="challenges" className="text-red-500">{validation.errors.challenges}</div> : null }
              </div>

              {/* <div className="xl:col-span-12">
                <label htmlFor="strengthInput" className="inline-block mb-2 text-base font-medium">Identified areas of strength</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="strengthInput" name="strength" placeholder="State the strength for the period"
                  value={validation.values.strength}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.strength && validation.errors.strength ? <div id="strength" className="text-red-500">{validation.errors.strength}</div> : null }
              </div>
             
              <div className="xl:col-span-12">
                <label htmlFor="improvementsInput" className="inline-block mb-2 text-base font-medium">Identified areas where improvement is needed.</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="improvementsInput" name="improvements" placeholder="Identified areas where improvement is needed."
                  value={validation.values.improvements}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.improvements && validation.errors.improvements ? <div id="improvements" className="text-red-500">{validation.errors.improvements}</div> : null }
              </div>

              <div className="xl:col-span-12">
                <label htmlFor="recommendationsInput" className="inline-block mb-2 text-base font-medium">Training recommendations / other comments</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="recommendationsInput" name="recommendations" placeholder="State the challengies"
                  value={validation.values.recommendations}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.recommendations && validation.errors.recommendations ? <div id="recommendations" className="text-red-500">{validation.errors.recommendations}</div> : null }
              </div> */}

              {isEdit && (
                <div className="xl:col-span-12">
                    <label htmlFor="statusInput" className="inline-block mb-2 text-base font-medium">Status</label>
                    <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                        id="statusInput"
                        name="status"
                        onChange={validation.handleChange}
                        value={validation.values.status || ""}
                    >
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="opened">Open</option>
                        <option value="closed">Close</option>
                    </select>
                    {validation.touched.status && validation.errors.status ? (
                        <p className="text-red-400">{validation.errors.status}</p>
                    ) : null}
                </div>
              )}

            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addAppraisalModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Submit Apraisal"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Appraisals;


