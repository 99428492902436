import React, { ChangeEvent, useEffect, useState } from "react";
import { BadgeCheck, Dribbble, Facebook, Github, Globe, ImagePlus, Instagram, Linkedin, Mail, MapPin, MoreHorizontal, UserCircle } from "lucide-react";

//import images
import dashboard from "assets/images/dashboard.png";
import avatar1 from "assets/images/users/avatar-1.png";

// react-redux
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

const WelcomeWidget = () => {

    const selectDataList = createSelector(
        (state: any) => state.Users,
        (hrState: any) => hrState.HRManagment,
        (state, hrState) => ({
            dataList: state.userProfile,
            checkAppraisal: hrState.checkAppraisalCategories,
        })
    );
    const { dataList, checkAppraisal} = useSelector(selectDataList);
    const [user, setData] = useState<any>({});
    const [appraisal, setAppraisal] = useState<any>({});

    useEffect(() => {
        setData(dataList?.data);
        setAppraisal(checkAppraisal?.data);
    }, [dataList, checkAppraisal]);
    

    const [selectedImage, setSelectedImage] = React.useState<string | ArrayBuffer | null>(avatar1);

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {

        const file = event.target.files?.[0];

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        }
    };
    return (
        <React.Fragment>
            <div className="relative col-span-12 overflow-hidden card 2xl:col-span-8">
                {/* <div className="absolute inset-0">
                    <svg xmlns="http://www.w3.org/2000/svg" className="size-100" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"  width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
                        <g mask="url(&quot;#SvgjsMask1000&quot;)" fill="none">
                            <use xlinkHref="#SvgjsSymbol1007" x="0" y="0"></use>
                            <use xlinkHref="#SvgjsSymbol1007" x="720" y="0"></use>
                        </g>
                        <defs>
                            <mask id="SvgjsMask1000">
                                <rect width="1440" height="560" fill="#ffffff"></rect>
                            </mask>
                            <path d="M-1 0 a1 1 0 1 0 2 0 a1 1 0 1 0 -2 0z" id="SvgjsPath1003"></path>
                            <path d="M-3 0 a3 3 0 1 0 6 0 a3 3 0 1 0 -6 0z" id="SvgjsPath1004"></path>
                            <path d="M-5 0 a5 5 0 1 0 10 0 a5 5 0 1 0 -10 0z" id="SvgjsPath1001"></path>
                            <path d="M2 -2 L-2 2z" id="SvgjsPath1005"></path>
                            <path d="M6 -6 L-6 6z" id="SvgjsPath1002"></path>
                            <path d="M30 -30 L-30 30z" id="SvgjsPath1006"></path>
                        </defs>
                        <symbol id="SvgjsSymbol1007">
                            <use xlinkHref="#SvgjsPath1001" x="30" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="30" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="30" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="30" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="30" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="30" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="30" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="30" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="30" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="30" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="90" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="90" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="90" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="90" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1004" x="90" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="90" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="90" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="90" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="90" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="90" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="150" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="150" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="150" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="150" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="150" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="150" y="330" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1004" x="150" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="150" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="150" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="150" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="210" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="210" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="210" y="150" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1002" x="210" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="210" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="210" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="210" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="210" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="210" y="510" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1003" x="210" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="270" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="270" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="270" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="270" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="270" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="270" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="270" y="390" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1002" x="270" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="270" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="270" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="330" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="330" y="90" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1002" x="330" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="330" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="330" y="270" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1001" x="330" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="330" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="330" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="330" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="330" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1004" x="390" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="390" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="390" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="390" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="390" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="390" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="390" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="390" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="390" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="390" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="450" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1004" x="450" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="450" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="450" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="450" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="450" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="450" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="450" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="450" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="450" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="510" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="510" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="510" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="510" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="510" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1004" x="510" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="510" y="390" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1001" x="510" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="510" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="510" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="570" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="570" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="570" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="570" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="570" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="570" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="570" y="390" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1005" x="570" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="570" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="570" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="630" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="630" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="630" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="630" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="630" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="630" y="330" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1002" x="630" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="630" y="450" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1001" x="630" y="510" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="630" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="690" y="30" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="690" y="90" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="690" y="150" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1002" x="690" y="210" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1005" x="690" y="270" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1001" x="690" y="330" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="690" y="390" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1003" x="690" y="450" stroke="rgba(32, 43, 61, 1)"></use>
                            <use xlinkHref="#SvgjsPath1006" x="690" y="510" stroke="rgba(32, 43, 61, 1)" strokeWidth="3"></use>
                            <use xlinkHref="#SvgjsPath1003" x="690" y="570" stroke="rgba(32, 43, 61, 1)"></use>
                        </symbol>
                    </svg>
                </div> */}
                {/* <div className="relative card-body">
                    <div className="grid items-center grid-cols-12">
                        <div className="col-span-12 lg:col-span-8 2xl:col-span-7">

                            <h5 className="mb-3 font-normal tracking-wide text-slate-200">Welcome Paula Keenan 🎉</h5>
                            <p className="mb-5 text-slate-400">An ecommerce dashboard has just that purpose. It provides your ecommerce team with a clear overview of key financial and website KPIs at any time.</p>
                            <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-500/20 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-500/20 dark:ring-custom-400/20">Take a Product</button>
                        </div>
                        <div className="hidden col-span-12 2xl:col-span-3 lg:col-span-2 lg:col-start-11 2xl:col-start-10 lg:block">
                            <img src={dashboard} alt="" className="h-40 ltr:2xl:ml-auto rtl:2xl:mr-auto" />
                        </div>
                    </div>
                </div> */}

                <div className="relative card-body">
                    {/* <div className="grid items-center grid-cols-12"> */}

                        <div className="grid grid-cols-1 gap-5 lg:grid-cols-12 2xl:grid-cols-12">
                            <div className="lg:col-span-2 2xl:col-span-2">
                                <div className="relative image-center inline-block size-20 rounded-full shadow-md bg-slate-100 profile-user xl:size-28">
                                    <img src={user?.image ?? avatar1} alt="" className="object-cover border-0 rounded-full img-thumbnail user-profile-image" />
                                </div>
                            </div>
                            <div className="lg:col-span-10 2xl:col-span-10 ">
                                <h5 className="mb-1">Welcome {user?.username} - {user?.employee_id} <BadgeCheck className="inline-block size-4 text-sky-500 fill-sky-100 dark:fill-custom-500/20"></BadgeCheck></h5>
                                <div className="flex gap-3 mb-2">
                                    <p className="text-slate-500 dark:text-zink-200"><UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle> {user?.role ? user.role.name : 'NA'}</p>
                                    <p className="text-slate-500 dark:text-zink-200"><UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle> {user?.role ? user.department.name : 'NA'}</p>
                                    <p className="text-slate-500 dark:text-zink-200"><UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle> {user?.position ? user?.position.name : 'NA'} - {user?.position ? user?.position.user_category.name : 'NA'} </p>
                                </div>

                                <div className="flex gap-3 mb-4">
                                    <p className="text-slate-500 dark:text-zink-200"><MapPin className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></MapPin> {user ? user.contact_address : 'NA'}</p>
                                </div>
                                <ul className="flex flex-wrap gap-3 mt-4 text-center divide-x divide-slate-200 dark:divide-zink-500 rtl:divide-x-reverse">
                                    <li className="px-5">
                                        <h5>{user ? user?.entitled_leave_days : 0}</h5>
                                        <p className="text-slate-500 dark:text-zink-200">Entitled Leave</p>
                                    </li>
                                    <li className="px-5">
                                        <h5>{user ? user?.leave_carried_over : 0}</h5>
                                        <p className="text-slate-500 dark:text-zink-200">Leave carried over</p>
                                    </li>

                                    <li className="px-5">
                                        <h5>{user ? user?.total_leave_days : 'NA'}</h5>
                                        <p className="text-slate-500 dark:text-zink-200">Total No. vacation</p>
                                    </li>
                                    <li className="px-5">
                                        <h5>{user ? user?.taken_leave_days : 0}</h5>
                                        <p className="text-slate-500 dark:text-zink-200">Leave Taken</p>
                                    </li>
                                    <li className="px-5">
                                        <h5>{user ? user?.pending_leave_days : 0}</h5>
                                        <p className="text-slate-500 dark:text-zink-200">Leave Pending</p>
                                    </li>
                                </ul>
                                <p className="mt-4 text-slate-500 dark:text-zink-200 "><b>Supervisor:</b> {user ? user?.supervisor : ''}</p>

                                {
                                    appraisal?.status ? (
                                        <div className="px-4 py-3 mb-2 mt-2 text-sm text-blue-500 border border-blue-200 rounded-md bg-blue-50 dark:bg-blue-400/20 dark:border-blue-500/50">
                                            <span className="font-bold">{appraisal?.notification} Alert !!!:</span> {appraisal?.category?.type == 'half_yr' ? `Half Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal` : appraisal?.category?.type == 'full_yr' ? `Full Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal` : `Confirmation - ${appraisal?.category?.month}, ${appraisal?.category?.year}`} is open FYA, Kindly click on Start Now button to proceed with your appraisal process. 
                                            <Link to={`/appaisals`} className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border transition-all duration-200 ease-linear bg-blue-100 border-transparent text-blue-500 hover:bg-blue-200 dark:bg-blue-400/20 dark:hover:bg-blue-400/30 dark:border-transparent ltr:ml-1 rtl:mr-1">Start Now</Link>
                                        </div>
                                    ) :('')
                                }

                                {/* <div className="flex gap-2 mt-4">
                                    <a href="#!" className="flex items-center justify-center transition-all duration-200 ease-linear rounded size-9 text-sky-500 bg-sky-100 hover:bg-sky-200 dark:bg-sky-500/20 dark:hover:bg-sky-500/30">
                                        <Facebook className="size-4"></Facebook>
                                    </a>
                                    <a href="#!" className="flex items-center justify-center text-pink-500 transition-all duration-200 ease-linear bg-pink-100 rounded size-9 hover:bg-pink-200 dark:bg-pink-500/20 dark:hover:bg-pink-500/30">
                                        <Instagram className="size-4"></Instagram>
                                    </a>
                                    <a href="#!" className="flex items-center justify-center text-red-500 transition-all duration-200 ease-linear bg-red-100 rounded size-9 hover:bg-red-200 dark:bg-red-500/20 dark:hover:bg-red-500/30">
                                        <Globe className="size-4"></Globe>
                                    </a>
                                    <a href="#!" className="flex items-center justify-center transition-all duration-200 ease-linear rounded text-custom-500 bg-custom-100 size-9 hover:bg-custom-200 dark:bg-custom-500/20 dark:hover:bg-custom-500/30">
                                        <Linkedin className="size-4"></Linkedin>
                                    </a>
                                    <a href="#!" className="flex items-center justify-center text-pink-500 transition-all duration-200 ease-linear bg-pink-100 rounded size-9 hover:bg-pink-200 dark:bg-pink-500/20 dark:hover:bg-pink-500/30">
                                        <Dribbble className="size-4"></Dribbble>
                                    </a>
                                    <a href="#!" className="flex items-center justify-center transition-all duration-200 ease-linear rounded size-9 text-slate-500 bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500">
                                        <Github className="size-4"></Github>
                                    </a>
                                </div> */}
                            </div>

                        </div>
                
                    {/* </div> */}
                </div>

            </div>
        </React.Fragment>
    );
};

export default WelcomeWidget;
