import { APIService } from "../http/axios_service";

const api = new APIService();

export const postLogin = (data: any) => api.create(`/auth/login`, data);

// Admin Settings
//Roles
export const getRoles = (params: any) => api.get(`/role`, params);
export const getActiveRoles = () => api.get(`/role/active`, null);
export const getRoleDetails = (id?: any) => api.get(`/role/${id}`, null);
export const addRole = (data: any) => api.create(`/role`, data);
export const updateRole = (data: any) => api.update(`/role/${data.id}`, data);
export const deleteRole = (id: any) => api.delete(`/role/${id}`, null);

//Departments
export const getDepartments = (params: any) => api.get(`/departments`, params);
export const getDepartmentDetails = (id?: any) =>api.get(`/departments/${id}`, null);
export const getActiveDepartments = () => api.get(`/departments/active`, null);
export const addDepartment = (data: any) => api.create(`/departments`, data);
export const updateDepartment = (data: any) =>api.update(`/departments/${data.id}`, data);
export const deleteDepartment = (id: any) =>api.delete(`/departments/${id}`, null);

// Positions
export const getPositions = (params: any) => api.get(`/positions`, params);
export const getActivePositions = () => api.get(`/positions/active`, null);
export const getPositionDetails = (id?: any) =>
  api.get(`/positions/${id}`, null);
export const addPosition = (data: any) => api.create(`/positions`, data);
export const updatePosition = (data: any) =>
  api.update(`/positions/${data.id}`, data);
export const deletePosition = (id: any) => api.delete(`/positions/${id}`, null);

// User Category
export const getUserCategory = (params: any) =>api.get(`/user_category`, params);
export const getActiveUserCategory = () =>api.get(`/user_category/active`, null);
export const getUserCategoryDetails = (id?: any) =>api.get(`/user_category/${id}`, null);
export const addUserCategory = (data: any) =>api.create(`/user_category`, data);
export const updateUserCategory = (data: any) =>api.update(`/user_category/${data.id}`, data);
export const deleteUserCategory = (id: string) =>api.delete(`/user_category/${id}`, null);

// Users
// List View
export const getUserList = () => api.get(`/users`, null);
export const getUserMetrix = () => api.get(`/users/metrix`, null);
export const getUserProfile = () => api.get(`/auth/profile`, null);
export const getUserCelebrationMetrix = () => api.get(`/users/celebration_metrix`, null);
export const getUsersBirthdays = () => api.get(`/users/todays_birthday`, null);
export const getUsersAnivarsaries = () => api.get(`/users/todays_aniversaries`, null);
export const getUsersCelebrations = () => api.get(`/users/monthly_celebrations`, null);
export const getUserDetails = (id?: any) => api.get(`/users/${id}`, null);
export const addUserList = (data: any) => api.create(`/users`, data);
export const updateUserList = (data: any) => api.update(`/users/${data.id}`, data);
export const deleteUserList = (user: any) => api.delete(`/users`, { headers: { user } });
export const uploadMultiPartFiles = (data: any) => api.create(`/minio-client/upload_buffer`, data);

// Grid View
export const getUserGrid = () => api.get(`/users`, null);
export const addUserGrid = (data: any) => api.create(`/users`, data);
export const updateUserGrid = (data: any) => api.update(`/users`, data);
export const deleteUserGrid = (user: any) =>api.delete(`/users`, { headers: { user } });

//Appraisal Category
export const getAppraisalCategories = (params: any) => api.get(`/appraisal_category`, params);
export const getAppraisalCategoryDetails = (id?: any) =>api.get(`/appraisal_category/${id}`, null);
export const getActiveAppraisalCategories = () => api.get(`/appraisal_category/opened`, null);
export const checkOpenedAppraisalCategories = () => api.get(`/appraisal_category/check_opened`, null);
export const addAppraisalCategory = (data: any) => api.create(`/appraisal_category`, data);
export const updateAppraisalCategory = (data: any) =>api.update(`/appraisal_category/${data.id}`, data);
export const deleteAppraisalCategory = (id: any) =>api.delete(`/appraisal_category/${id}`, null);

//Appraisal KPI
export const getAppraisalKpis = (params: any) => api.get(`/appraisal_kpi`, params);
export const getAppraisalKpiDetails = (id?: any) =>api.get(`/appraisal_kpi/${id}`, null);
export const getActiveAppraisalKpis = () => api.get(`/appraisal_kpi/active`, null);
export const addAppraisalKpi = (data: any) => api.create(`/appraisal_kpi`, data);
export const updateAppraisalKpi = (data: any) =>api.update(`/appraisal_kpi/${data.id}`, data);
export const deleteAppraisalKpi = (id: any) =>api.delete(`/appraisal_kpi/${id}`, null);

//Appraisal
export const getAppraisals = (params: any) => api.get(`/appraisal`, params);
export const getUserAppraisals = (params: any) => api.get(`/appraisal/user`, params);
export const getAppraisalDetails = (id?: any) =>api.get(`/appraisal/${id}`, null);
export const getActiveAppraisals = () => api.get(`/appraisal/active`, null);
export const createAppraisal = (data: any) => api.create(`/appraisal`, data);
export const updateAppraisal = (data: any) =>api.update(`/appraisal/${data.id}`, data);
export const deleteAppraisal = (id: any) =>api.delete(`/appraisal/${id}`, null);

//Performance 
export const getCoulleaguePerformance = () => api.get(`/performance/coulleague`, null);
export const getAppraisalPerformance = (appraisal_id: any) => api.get(`/performance/appraisal/${appraisal_id}`, null);
