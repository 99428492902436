import React, { useEffect, useState } from 'react';
import { TotalEmployeeChart } from './Charts';
import CountUp from 'react-countup';
import ApplicationReceived from './ApplicationReceived';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const Widgets = () => {

    const selectDataList = createSelector(
        (state: any) => state.Users,
        (user) => ({
            userMetrix: user.userMetrix,
            userCelebrationMetrix: user.userCelebrationMetrix,
        })
    );
    
    const { userMetrix, userCelebrationMetrix} = useSelector(selectDataList);
    const [metrix, setMetrix] = useState<any>([]);
    const [celebrationMetrix, setCelebrationMetrix] = useState<any>([]);
    
    useEffect(() => {
        setMetrix(userMetrix?.data);
        setCelebrationMetrix(userCelebrationMetrix?.data);
    }, [userMetrix, userCelebrationMetrix]);

    return (
        <React.Fragment>
            <div className="col-span-12 md:order-3 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Active Employees</p>
                            <h5 className="mt-3 mb-4">
                                <CountUp end={metrix?.active} className="counter-value" />
                            </h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="totalEmployee" dataChartColor='["bg-green-500"]' series={[50]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-green-500">0%</span> On Leave</p>
                        <p className="text-slate-500 dark:text-slate-200">This Month</p>
                    </div>
                </div>
            </div>

            <div className="col-span-12 md:order-4 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Inactive Employees</p>
                            <h5 className="mt-3 mb-4">  <CountUp end={metrix?.inactive} className="counter-value" /></h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="totalApplication" dataChartColor='["bg-red-500"]' series={[60]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-green-500">{metrix?.pending}%</span> Pending</p>
                        <p className="text-slate-500 dark:text-slate-200">This Month</p>
                    </div>
                </div>
            </div>

            <ApplicationReceived />

            <div className="col-span-12 md:order-5 2xl:order-6 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Total Birtday</p>
                            <h5 className="mt-3 mb-4"> <CountUp end={celebrationMetrix?.birthday} className="counter-value" /></h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="hiredCandidates" dataChartColor='["bg-purple-500"]' series={[50]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-red-500">0%</span> Celebrated</p>
                        <p className="text-slate-500 dark:text-slate-200">This Month</p>
                    </div>
                </div>
            </div>
            <div className="col-span-12 md:order-6 2xl:order-7 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Total Anniversary</p>
                            <h5 className="mt-3 mb-4"><CountUp end={celebrationMetrix?.aniversary} className="counter-value" /></h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="rejectedCandidates" dataChartColor='["bg-custom-500"]' series={[0]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-red-500">0%</span> Celebrated</p>
                        <p className="text-slate-500 dark:text-slate-200">This Month</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Widgets;
