// dashboard
import Analytics from "pages/Dashboards/Analytics";
import Ecommerce from "pages/Dashboards/Ecommerce";
import Email from "pages/Dashboards/Email";
import HRDashboard from "pages/Dashboards/HR";
import SocialMediaDashboard from "pages/Dashboards/SocialMedia";

// HR Management
import EmployeeList from "pages/Admin/Users/Account/EmployeeList";
import Holidays from "pages/HRManagement/Holidays";
import LeaveManageEmployee from "pages/HRManagement/Leaves Manage/LeaveManageEmployee";
import AddLeaveEmployee from "pages/HRManagement/Leaves Manage/AddLeaveEmployee";
import LeaveManageHR from "pages/HRManagement/Leaves Manage/LeaveManageHR";
import AddLeaveHR from "pages/HRManagement/Leaves Manage/AddLeaveHR";
import AttendanceHR from "pages/HRManagement/Attendance/AttendanceHR";
import MainAttendance from "pages/HRManagement/Attendance/MainAttendance";
import Departments from "pages/Admin/Departments";
import Roles from "pages/Admin/roles";
import UserCategories from "pages/Admin/userCategories";
import Positions from "pages/Admin/positions";
import Estimates from "pages/HRManagement/Sales/Estimates";
import Payments from "pages/HRManagement/Sales/Payments";
import Expenses from "pages/HRManagement/Sales/Expenses";
import EmployeeSalary from "pages/HRManagement/Payroll/EmployeeSalary";
import Payslip from "pages/HRManagement/Payroll/Payslip";
import CreatePayslip from "pages/HRManagement/Payroll/CreatePayslip";

import AppraisalCategories from "pages/HRManagement/Appraisals/appraisal-categories";
import AppraisalDetails from "pages/HRManagement/Appraisals/appraisal-details";
import AppraisalKpis from "pages/HRManagement/Appraisals/appraisal-kpis";
import AppraisalAdmin from "pages/HRManagement/Appraisals/appraisal-admin";
import Appraisals from "pages/HRManagement/Appraisals/appraisals";

// Users
import CreateUser from "pages/Admin/Users/Registration/FormWizard";
import UserListView from "pages/Admin/Users/ListView";
import UserGridView from "pages/Admin/Users/GridView";
import EmployeeOverview from "pages/Admin/Users/Account/employeeOverview";
import Account from "pages/Admin/Users/Account";

// auth
import Basic from "Auth/AuthenticationInner/Login/Basic";
import LoginCover from "Auth/AuthenticationInner/Login/LoginCover";
import LoginBoxed from "Auth/AuthenticationInner/Login/LoginBoxed";
import LoginModern from "Auth/AuthenticationInner/Login/LoginModern";

//Register
import BasicRegister from "Auth/AuthenticationInner/Register/Basic";
import RegisterCover from "Auth/AuthenticationInner/Register/RegisterCover";
import RegisterBoxed from "Auth/AuthenticationInner/Register/RegisterBoxed";
import RegisterModern from "Auth/AuthenticationInner/Register/RegisterModern";

// EmailVerify
import BasicEmailVerify from "Auth/AuthenticationInner/VerifyEmail/Basic";
import EmailCover from "Auth/AuthenticationInner/VerifyEmail/EmailCover";
import EmailModern from "Auth/AuthenticationInner/VerifyEmail/EmailModern";

// TwoSteps
import BasicTwoSteps from "Auth/AuthenticationInner/TwoSteps/Basic";
import TwoStepsCover from "Auth/AuthenticationInner/TwoSteps/TwoStepsCover";
import TwoStepsBoxed from "Auth/AuthenticationInner/TwoSteps/TwoStepsBoxed";
import TwoStepsModern from "Auth/AuthenticationInner/TwoSteps/TwoStepsModern";

// Logout
import BasicLogout from "Auth/AuthenticationInner/Logout/Basic";
import LogoutCover from "Auth/AuthenticationInner/Logout/LogoutCover";
import LogoutBoxed from "Auth/AuthenticationInner/Logout/LogoutBoxed";
import LogoutModern from "Auth/AuthenticationInner/Logout/LogoutModern";

// Reset Password
import BasicResetPassword from "Auth/AuthenticationInner/ResetPassword/Basic";
import ResetPasswordCover from "Auth/AuthenticationInner/ResetPassword/ResetPasswordCover";
import ResetPasswordBoxed from "Auth/AuthenticationInner/ResetPassword/ResetPasswordBoxed";
import ResetPasswordModern from "Auth/AuthenticationInner/ResetPassword/ResetPasswordModern";

// Create Password
import BasicCreatePassword from "Auth/AuthenticationInner/CreatePassword/Basic";
import CreatePasswordModern from "Auth/AuthenticationInner/CreatePassword/CreatePasswordModern";
import CreatePasswordCover from "Auth/AuthenticationInner/CreatePassword/CreatePasswordCover";
import CreatePasswordBoxed from "Auth/AuthenticationInner/CreatePassword/CreatePasswordBoxed";
import Login from "Auth/Authentication/Login";
import Logout from "Auth/Authentication/LogOut";
import Pages404 from "Auth/AuthenticationInner/Pages404";
import Register from "Auth/Authentication/Register";
import UserProfile from "Auth/Authentication/UserProfile";
import ComingSoon from "Auth/AuthenticationInner/ComingSoon";
import Offline from "Auth/AuthenticationInner/Offline";
import Maintenance from "Auth/AuthenticationInner/Maintenance";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Ecommerce },
  { path: "/dashboard", component: Ecommerce },
  { path: "/dashboards-analytics", component: Analytics },
  { path: "/dashboards-email", component: Email },
  { path: "/dashboards-hr", component: HRDashboard },
  { path: "/dashboards-social", component: SocialMediaDashboard },

  // HR Management
  { path: "/apps-hr-employee", component: EmployeeList },
  { path: "/apps-hr-holidays", component: Holidays },
  { path: "/apps-hr-leave-employee", component: LeaveManageEmployee },
  { path: "/apps-hr-create-leave-employee", component: AddLeaveEmployee },
  { path: "/apps-hr-leave", component: LeaveManageHR },
  { path: "/apps-hr-create-leave", component: AddLeaveHR },
  { path: "/apps-hr-attendance", component: AttendanceHR },
  { path: "/apps-hr-attendance-main", component: MainAttendance },
  { path: "/apps-hr-department", component: Departments },
  { path: "/apps-hr-sales-estimates", component: Estimates },
  { path: "/apps-hr-sales-payments", component: Payments },
  { path: "/apps-hr-sales-expenses", component: Expenses },
  { path: "/apps-hr-payroll-employee-salary", component: EmployeeSalary },
  { path: "/apps-hr-payroll-payslip", component: Payslip },
  { path: "/apps-hr-payroll-create-payslip", component: CreatePayslip },

  //admin Management
  { path: "/apps-admin-roles", component: Roles },
  { path: "/apps-admin-userCategories", component: UserCategories },
  { path: "/apps-admin-positions", component: Positions },

  // Users
  { path: "/apps-create-user", component: CreateUser },
  { path: "/apps-users-list", component: UserListView },
  { path: "/apps-users-grid", component: UserGridView },
  { path: "/apps-users-overview/:id", component: EmployeeOverview },

  // profile
  { path: "/user-profile", component: UserProfile },

  { path: "/user-account/:id", component: Account },
  // { path: "/pages-account-settings", component: Settings },
  // { path: "/pages-pricing", component: Pricing },
  // { path: "/pages-faqs", component: Faqs },
  // { path: "/pages-contact-us", component: ContactUs },

    //Appraisal Category
    { path: "/appraisal-categories", component: AppraisalCategories },
    { path: "/appaisal-admin", component: AppraisalAdmin},
    { path: "/appaisals", component: Appraisals},
    { path: "/appaisals/:id", component: AppraisalDetails},
    { path: "/appraisal-kpis", component: AppraisalKpis},
];

const publicRoutes = [
  // auth
  { path: "/auth-login-basic", component: Basic },
  { path: "/auth-login-cover", component: LoginCover },
  { path: "/auth-login-boxed", component: LoginBoxed },
  { path: "/auth-login-modern", component: LoginModern },

  // Register
  { path: "/auth-register-basic", component: BasicRegister },
  { path: "/auth-register-cover", component: RegisterCover },
  { path: "/auth-register-boxed", component: RegisterBoxed },
  { path: "/auth-register-modern", component: RegisterModern },

  // Verify Email
  { path: "/auth-verify-email-basic", component: BasicEmailVerify },
  { path: "/auth-verify-email-cover", component: EmailCover },
  { path: "/auth-verify-email-modern", component: EmailModern },

  // two steps
  { path: "/auth-two-steps-basic", component: BasicTwoSteps },
  { path: "/auth-two-steps-cover", component: TwoStepsCover },
  { path: "/auth-two-steps-boxed", component: TwoStepsBoxed },
  { path: "/auth-two-steps-modern", component: TwoStepsModern },

  // logout
  { path: "/auth-logout-basic", component: BasicLogout },
  { path: "/auth-logout-cover", component: LogoutCover },
  { path: "/auth-logout-boxed", component: LogoutBoxed },
  { path: "/auth-logout-modern", component: LogoutModern },

  //Reset Password
  { path: "/auth-reset-password-basic", component: BasicResetPassword },
  { path: "/auth-reset-password-cover", component: ResetPasswordCover },
  { path: "/auth-reset-password-boxed", component: ResetPasswordBoxed },
  { path: "/auth-reset-password-modern", component: ResetPasswordModern },

  //Create Password
  { path: "/auth-create-password-basic", component: BasicCreatePassword },
  { path: "/auth-create-password-cover", component: CreatePasswordCover },
  { path: "/auth-create-password-boxed", component: CreatePasswordBoxed },
  { path: "/auth-create-password-modern", component: CreatePasswordModern },

  // coming soon
  { path: "/pages-coming-soon", component: ComingSoon },

  // Error
  { path: "/pages-offline", component: Offline },
  { path: "/pages-404", component: Pages404 },

  // Maintenance
  { path: "/pages-maintenance", component: Maintenance },

  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
