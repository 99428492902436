import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";

// Icons
import { Search, Plus, Trash2, Pencil } from "lucide-react";
import TableContainer from "Common/TableContainer";

import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getRoles as onGetRoles,
  addRole as onAddRole,
  updateRole as onUpdateRole,
  deleteRole as onDeleteRole,
} from "slices/thunk";
import { ToastContainer } from "react-toastify";
import Pagination from "Common/Pagination";
import CreatableSelect from "react-select/creatable";

interface Option {
  readonly label: string;
  readonly value?: string;
  readonly options?: Option[];
  readonly isDisabled?: boolean;
}
interface FormValues {
  name: string;
  description: string;
  status: string;
  module_access: string[];
  permission: string[];
}

const Roles = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.roleslist,
    })
  );

  const { dataList} = useSelector(selectDataList);

  const [role, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const moduleValues: Option[] = [];
  const permissionValues: Option[] = [];

  const [modules, setModules] = useState<readonly Option[]>([
    moduleValues[0],
    moduleValues[1],
  ]);
  const [permissions, setPermissions] = useState<readonly Option[]>([
    permissionValues[0],
    permissionValues[1],
  ]);

  // Get Data
  useEffect(() => {
    dispatch(onGetRoles({ page: 1, per_page: 12 }));
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
  }, [dataList]);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteRole(eventData.id));
      setDeleteModal(false);
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  const validation = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: eventData?.name || "",
      description: eventData?.description || "",
      status: eventData?.status || "",
      module_access: [],
      permission: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter name"),
      description: Yup.string().required("Please Enter description"),
      status: Yup.string().required("Please Enter status"),
      module_access: Yup.array().min(1, "Please select at least one module"), // Add validation for module_access
      permission: Yup.array().min(1, "Please select at least one permission"),
    }),
    onSubmit: (values, { setFieldTouched, setFieldError }) => {
      let valid = true;

      // Manually validate module_access and permission fields
      if (modules.length === 0) {
        setFieldTouched("module_access", true);
        setFieldError("module_access", "Please select at least one module");
        valid = false;
      }
      if (permissions.length === 0) {
        setFieldTouched("permission", true);
        setFieldError("permission", "Please select at least one permission");
        valid = false;
      }

      // Only submit if all validations pass
      if (valid) {
        const formData = {
          ...values,
          module_access: modules?.map((module: any) => module?.value),
          permission: permissions?.map((permission: any) => permission?.value),
        };

        if (isEdit) {
          const updateData = {
            id: eventData ? eventData.id : 0,
            ...formData,
          };
          dispatch(onUpdateRole(updateData));
        } else {
          const newData = {
            ...formData,
            id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
            userId:
              "#TW15000" +
              (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          };
          dispatch(onAddRole(newData));
        }

        // Close the modal and reset the form after successful submission
        toggle();
      }
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
      setModules([]);
      setPermissions([]);
    } else {
      setShow(true);
      setEventData("");
      setModules([]);
      setPermissions([]);
      validation.resetForm();
    }
  }, [show, validation]);

  // Status
  const Status = ({ item }: any) => {
    switch (item) {
      case "active":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            {item}
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
            {item}
          </span>
        );
      case "inactive":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  // columns

  const columns = useMemo(
    () => [
      {
        header: "#",
        // accessorKey: "id: index + 1",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex gap-2">{cell.row.index + 1}</div>
        ),
      },
      {
        header: "Role",
        accessorKey: "name",
        enableColumnFilter: false,
      },

      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
      },
      {
        header: "Total Employees",
        accessorKey: "total_employees",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-2">
            <Link
              to="#!"
              data-modal-target="addDepartmentModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/20"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-red-500 dark:hover:text-red-500 hover:bg-red-100 dark:hover:bg-red-500/20"
              onClick={() => {
                const data = cell.row.original;
                onClickDelete(data);
              }}
            >
              <Trash2 className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  // Pagination
  const [currentPage, setCurrentPage] = useState<any>(1);

  const perPageData = 12;

  useEffect(() => {
    setCurrentPage(currentPage);
    dispatch(onGetRoles({ page: currentPage, per_page: perPageData }));
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <BreadCrumb title="Role" pageTitle="Role Management" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">Role List</h6>
            <div className="shrink-0">
              <Link
                to="#!"
                data-modal-target="addDepartmentModal"
                type="button"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                onClick={toggle}
              >
                <Plus className="inline-block size-4" />{" "}
                <span className="align-middle">Add Role</span>
              </Link>
            </div>
          </div>
          {role && role.length > 0 ? (
            <TableContainer
              isPagination={false}
              columns={columns || []}
              data={role || []}
              customPageSize={12}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right"
              thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"  />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  We've searched more than 5+ Roles We did not find any Roles
                  for your search.
                </p>
              </div>
            </div>
          )}

          <Pagination
            perPageData={perPageData}
            data={dataList ? dataList?.data : dataList}
            total={dataList ? dataList?.meta.total : 0}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currentdata={dataList ? dataList?.data : []}
          />
        </div>
      </div>

      {/* Department Modal */}

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Role" : "Add Role"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              // return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="statusInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Status
                </label>
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="statusInput"
                  name="status"
                  onChange={validation.handleChange}
                  value={validation.values.status}
                >
                  <option value="">Select Status</option>
                  <option value="pending">Pending</option>
                  <option value="active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                {validation.touched.status && validation.errors.status ? (
                  <p className="text-red-400">{validation.errors.status}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="roleNameInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Role Name
                </label>
                <input
                  type="text"
                  id="roleNameInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Role name"
                  name="name"
                  onChange={validation.handleChange}
                  value={validation.values.name || ""}
                />
                {validation.touched.name && validation.errors.name ? (
                  <p className="text-red-400">{validation.errors.name}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="headOfInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Description
                </label>
                <input
                  type="text"
                  id="headOfInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Description"
                  name="description"
                  onChange={validation.handleChange}
                  value={validation.values.description || ""}
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <p className="text-red-400">
                    {validation.errors.description}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="moduleAccessInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Module Access
                </label>
                <CreatableSelect
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-text-unique-values"
                  data-choices
                  data-choices-text-unique-true
                  isMulti
                  name="module_access"
                  onChange={(newModules: any) => {
                    setModules(newModules);
                    validation.setFieldValue(
                      "module_access",
                      newModules.map((module: any) => module.value)
                    );
                  }}
                  // onChange={(newModule: any) => setModules(newModule)}
                  value={modules}
                />

                {validation.touched.module_access &&
                validation.errors.module_access ? (
                  <p className="text-red-400">
                    {validation.errors.module_access}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="permissionInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Permission
                </label>
                <CreatableSelect
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-text-unique-values"
                  data-choices
                  data-choices-text-unique-true
                  isMulti
                  name="permission"
                  // onChange={(newPermission: any) =>
                  //   setPermissions(newPermission)
                  // }
                  onChange={(newPermission: any) => {
                    setPermissions(newPermission);
                    validation.setFieldValue(
                      "permission",
                      newPermission.map((permission: any) => permission.value)
                    );
                  }}
                  value={permissions}
                />

                {validation.touched.permission &&
                validation.errors.permission ? (
                  <p className="text-red-400">{validation.errors.permission}</p>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDepartmentModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Add Role"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Roles;
